import ContentCard from '@src/components/cards/contentCard';
import VoteBadge from '@src/components/atoms/voteBadge';
import type { IDRepVote } from '@src/store/drep/types';
import { seperateDateTime, timeAgo } from '@src/utils/date';
import AnchorLink from '@src/components/atoms/links/anchor-link';
import ExternalLink from '@src/components/icons/externalLink';
import EmptyContent from '@src/components/cards/emptyContentCard';
import { noContentDescription } from '@src/constants';
import { determineExternalExplorerLink } from '@src/utils/network';
import environments from '@src/configs/environments';
import { getNetworkNameFromURL } from '@src/utils/network';
import { useRouter } from 'next/navigation';

interface DRepVotesProps {
  votes: IDRepVote[];
  isOwner: boolean;
}

export default function DRepVotes({ votes, isOwner }: DRepVotesProps) {
  const router = useRouter();
  if (votes.length === 0)
    return (
      <EmptyContent
        description={
          isOwner ? noContentDescription.owner.other : noContentDescription.user
        }
      />
    );

  return (
    <ContentCard>
      <table className="w-full rounded-lg border dark:border-dark-neutral-700">
        <thead>
          <tr>
            <th className="min-w-[69px]">Vote</th>
            <th className="min-w-[77px]">Type</th>
            <th className="min-w-[177px] md:min-w-[190px] xl:min-w-[390px]">
              GovAction
            </th>
            <th className="min-w-[144px]">Transaction</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {votes.map((vote, index) => {
            const dateTime = seperateDateTime(vote.time);
            return (
              <tr key={index}>
                <td>
                  <VoteBadge vote={vote.voteType} />
                </td>
                <td className="label12 font-medium dark:text-white">
                  {vote.govActionType}
                </td>
                <td className="body14 font-medium dark:text-white">
                  {
                    <div
                      onClick={() => {
                        router.push(`/governance_actions/${vote.govActionId}`);
                      }}
                      className="hover:cursor-pointer"
                    >
                      {vote.title || '-'}
                    </div>
                  }
                </td>
                <td className="flex items-center">
                  <div className="flex flex-col">
                    <span className="relative-time">{timeAgo(vote.time)}</span>
                    <span className="transaction-date">
                      {dateTime.date} | {dateTime.time}
                    </span>
                  </div>
                </td>
                <td>
                  <AnchorLink
                    href={`${determineExternalExplorerLink(
                      environments.CARDANO_NETWORK ||
                        (typeof window !== 'undefined'
                          ? getNetworkNameFromURL(window.location.href)
                          : 'MAINNET')
                    )}/transaction/${vote.voteTxHash}?tab=votes`}
                    target="new"
                  >
                    <ExternalLink className="h-[14px] w-[14px] cursor-pointer text-dark-neutral-700 dark:text-white" />
                  </AnchorLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </ContentCard>
  );
}
