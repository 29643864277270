import React from 'react';

export default function BadgeDollarSign(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4603_1342)">
        <path
          d="M13.3335 6.66585H8.33354C7.89151 6.66585 7.46759 6.84145 7.15503 7.15401C6.84247 7.46657 6.66687 7.89049 6.66687 8.33252C6.66687 8.77455 6.84247 9.19847 7.15503 9.51103C7.46759 9.82359 7.89151 9.99919 8.33354 9.99919H11.6669C12.1089 9.99919 12.5328 10.1748 12.8454 10.4873C13.1579 10.7999 13.3335 11.2238 13.3335 11.6659C13.3335 12.1079 13.1579 12.5318 12.8454 12.8444C12.5328 13.1569 12.1089 13.3325 11.6669 13.3325H6.66687M10.0002 14.9992V4.99919M3.20849 7.1826C3.08686 6.63471 3.10554 6.06497 3.26279 5.52622C3.42004 4.98746 3.71078 4.49714 4.10805 4.1007C4.50532 3.70427 4.99625 3.41455 5.53533 3.25843C6.07441 3.1023 6.64419 3.08482 7.19183 3.2076C7.49325 2.73619 7.9085 2.34823 8.39929 2.07951C8.89008 1.81078 9.44062 1.66992 10.0002 1.66992C10.5597 1.66992 11.1102 1.81078 11.601 2.07951C12.0918 2.34823 12.5071 2.73619 12.8085 3.2076C13.357 3.08429 13.9277 3.10169 14.4677 3.2582C15.0076 3.4147 15.4992 3.70522 15.8967 4.10273C16.2942 4.50024 16.5847 4.99183 16.7412 5.53177C16.8977 6.07171 16.9151 6.64246 16.7918 7.19093C17.2632 7.49236 17.6512 7.9076 17.9199 8.39839C18.1886 8.88918 18.3295 9.43972 18.3295 9.99927C18.3295 10.5588 18.1886 11.1094 17.9199 11.6001C17.6512 12.0909 17.2632 12.5062 16.7918 12.8076C16.9146 13.3552 16.8971 13.925 16.741 14.4641C16.5849 15.0032 16.2952 15.4941 15.8987 15.8914C15.5023 16.2886 15.012 16.5794 14.4732 16.7366C13.9345 16.8939 13.3647 16.9126 12.8168 16.7909C12.5158 17.2642 12.1002 17.6538 11.6086 17.9237C11.117 18.1936 10.5652 18.3351 10.0043 18.3351C9.44347 18.3351 8.89168 18.1936 8.40006 17.9237C7.90843 17.6538 7.49286 17.2642 7.19183 16.7909C6.64419 16.9137 6.07441 16.8962 5.53533 16.7401C4.99625 16.584 4.50532 16.2943 4.10805 15.8978C3.71078 15.5014 3.42004 15.0111 3.26279 14.4723C3.10554 13.9336 3.08686 13.3638 3.20849 12.8159C2.73346 12.5153 2.34217 12.0994 2.07103 11.6069C1.7999 11.1145 1.65771 10.5614 1.65771 9.99927C1.65771 9.43709 1.7999 8.88405 2.07103 8.39159C2.34217 7.89912 2.73346 7.48323 3.20849 7.1826Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4603_1342">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
