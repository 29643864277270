import Button from '@src/components/atoms/buttons/button';
interface TabComponentLeftContentProps {
  title: string;
  description: string;
  isButtonHidden?: boolean;
  handleNext?: () => void;
}

export default function TabComponentLeftContent({
  title,
  description,
  isButtonHidden = false,
  handleNext,
}: TabComponentLeftContentProps) {
  return (
    <div className="flex w-full flex-col dark:text-white md:max-w-[305px] lg:min-w-[305px]">
      <p className="body18 font-bold">{title}</p>
      <p className="body14 mt-2 md:mb-5">{description}</p>
      {!isButtonHidden && (
        <Button onClick={handleNext} className="hidden w-fit md:flex">
          Next
        </Button>
      )}
    </div>
  );
}
