import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import type { SocialLinksTabProps } from '@src/components/molecules/edit-profile-tabs/socialLinksTab';
import type {
  IDRepFormField,
  ReferenceWithId,
} from '@src/models/dtos/metadata';
import { v4 as uuid } from 'uuid';
import { isValidURLFormat } from '@src/utils/validationUtils';
import TabComponentLeftContent from '@src/components/molecules/edit-profile-tabs/tabComponentleftContent';
import InputField from '@src/components/atoms/textfield/inputField';
import Web from '@src/components/icons/socials/web';
import { getFieldValue } from '@src/utils/metadataUtils';
import { isEmpty } from 'lodash';
import DeleteIcon from '@src/components/icons/delete';
import Button from '@src/components/atoms/buttons/button';
import Plus from '@src/components/icons/plus';
import cn from 'classnames';
import { useFormHandlers } from '@src/lib/hooks/use-form-handler';

export default function IdentityLinksTab({
  setDRepRegistrationFormData,
  dRepRegistrationFormData,
}: SocialLinksTabProps) {
  const { identityLinksError, setIdentityLinksError, getLinks } =
    useFormHandlers(dRepRegistrationFormData, setDRepRegistrationFormData);
  const [links, setLinks] = useState<Array<ReferenceWithId>>(
    getLinks('Identity')
  );
  const [portfolioLink, setPortfolioLink] = useState(
    links.filter((link) => link.label.toString().toLowerCase() === 'portfolio')
  );

  const updateDRepRegistrationValue = (updatedLink: Array<ReferenceWithId>) => {
    setDRepRegistrationFormData((prevState: IDRepFormField) => ({
      ...prevState,
      references: [
        ...(prevState.references?.filter(
          (ref) => ref['@type'] !== 'Identity'
        ) || []),
        ...updatedLink,
      ],
    }));
  };

  const handleAddLink = () => {
    const updatedLink = [
      ...links,
      {
        id: uuid(),
        '@type': 'Identity',
        label: '',
        uri: '',
      },
    ];
    setLinks(updatedLink);
    updateDRepRegistrationValue(updatedLink);
  };

  const updateErrorState = (uuid: string, message?: string) => {
    const currentErrors = Array.isArray(identityLinksError?.errors)
      ? identityLinksError.errors
      : [];

    const updatedErrors = message
      ? [
          ...currentErrors.filter((err) => err.id !== uuid),
          { id: uuid, message },
        ]
      : currentErrors.filter((err) => err.id !== uuid);

    setIdentityLinksError({
      step: 4,
      errors: updatedErrors,
    });
  };

  const handleOnDelete = (id: string) => {
    updateErrorState(id, '');
    const updatedLink = links.filter((link) => link.id !== id);
    setLinks(updatedLink);
    updateDRepRegistrationValue(updatedLink);
  };

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    { uuid, isPortfolio = false }: { uuid: string; isPortfolio?: boolean }
  ) => {
    const { id, value } = e.target;

    const updateErrorIfNeeded = (id: string, value: string, uuid: string) => {
      if (id === 'uri' && !isValidURLFormat(value)) {
        updateErrorState(uuid, 'Invalid URL.');
      } else {
        updateErrorState(uuid, '');
      }
    };

    const updateLink = (link: ReferenceWithId) => {
      if (link.id === uuid) {
        updateErrorIfNeeded(id, value, uuid);
        return {
          ...link,
          [id]: value,
        };
      }
      return link;
    };

    const updatePortfolioLinks = () => {
      const updatedPortfolioLinks = portfolioLink.map(updateLink);
      setPortfolioLink(updatedPortfolioLinks);

      const isUuidInLinks = links.some((link) => link.id === uuid);

      return isUuidInLinks
        ? links.map(updateLink)
        : [...links, ...updatedPortfolioLinks];
    };

    const updatedLinks = isPortfolio
      ? updatePortfolioLinks()
      : links.map(updateLink);

    setLinks(updatedLinks);
    updateDRepRegistrationValue(updatedLinks);
  };

  useEffect(() => {
    const portfolioExists = portfolioLink.length > 0;
    const otherLinksExist = portfolioExists
      ? links.length !== 1
      : links.length !== 0;

    if (!portfolioExists) {
      setPortfolioLink([
        { id: uuid(), '@type': 'Identity', label: 'Portfolio', uri: '' },
      ]);
    }

    if (!otherLinksExist) {
      setLinks([
        ...links,
        ...(!portfolioExists
          ? [{ id: uuid(), '@type': 'Identity', label: 'Portfolio', uri: '' }]
          : []),
        ...(!otherLinksExist
          ? [{ id: uuid(), '@type': 'Identity', label: '', uri: '' }]
          : []),
      ]);
    }
  }, []);

  const handleErrorMessage = (identity: string) => {
    const errorMessage = identityLinksError?.errors.find(
      (err) => err.id === identity
    )?.message;
    return errorMessage || '';
  };

  return (
    <div className="flex flex-col gap-5 md:flex-row md:gap-14">
      <TabComponentLeftContent
        isButtonHidden
        title="Identity"
        description="Here, you can update your personal information, and manage your security settings."
      />
      <div className="flex w-full flex-col gap-5">
        <InputField
          id="uri"
          value={
            portfolioLink.length !== 0
              ? getFieldValue(portfolioLink[0].uri)
              : ''
          }
          onChange={(e) =>
            handleOnChange(e, { uuid: portfolioLink[0].id, isPortfolio: true })
          }
          preActions={[<Web key="web" className="h-5 w-5 dark:text-white" />]}
          label="Personal Website"
          placeholder="eg. https://johndoe.portfolio.com"
        />

        {links
          .filter((link) => link.label.toString().toLowerCase() !== 'portfolio')
          .map((link) => {
            return (
              <div
                key={link.id}
                className="flex w-full flex-col justify-between gap-6 md:flex-row"
              >
                <InputField
                  id="label"
                  className="w-full"
                  onChange={(e) => handleOnChange(e, { uuid: link.id })}
                  value={getFieldValue(link.label)}
                  label="Description/Title"
                  placeholder="eg. Personal Website"
                />
                <div
                  className={cn(
                    'flex w-full gap-6',
                    handleErrorMessage(link.id) ? 'items-center' : 'items-end'
                  )}
                >
                  <InputField
                    id="uri"
                    className="w-full"
                    value={getFieldValue(link.uri)}
                    onChange={(e) => handleOnChange(e, { uuid: link.id })}
                    helperText={handleErrorMessage(link.id)}
                    variant={
                      !isEmpty(handleErrorMessage(link.id))
                        ? 'error'
                        : 'neutral'
                    }
                    label="Url"
                    placeholder="eg. https://johndoe.portfolio.com"
                  />

                  {links.length > 2 && (
                    <div
                      onClick={() => handleOnDelete(link.id)}
                      className="flex h-11 w-11 cursor-pointer items-center justify-center rounded-xl border dark:border-dark-neutral-700 dark:bg-dark-neutral-600"
                    >
                      <DeleteIcon className="h-6 w-6 text-semantic-error-600" />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        {links.length < 8 && (
          <Button onClick={handleAddLink} className="w-fit" variant="gray">
            <span>Add detail</span> <Plus />
          </Button>
        )}
      </div>
    </div>
  );
}
