import cn from 'classnames';
import Badge from '@src/components/atoms/badges/badge';
export default function RegistrationBadge({
  amount,
}: {
  amount: number | null;
}) {
  const status =
    amount === null
      ? 'Update'
      : amount >= 0
        ? `Registration`
        : `Deregistration`;

  function getBadgeColor() {
    if (amount === null) {
      return 'information';
    } else if (amount >= 0) {
      return 'success';
    } else {
      return 'error';
    }
  }
  return (
    <Badge
      className={cn(
        status === 'Update' && 'dark:bg-dark-neutral-50 dark:text-white',
        'w-fit'
      )}
      variant="light"
      color={getBadgeColor()}
      label={status}
    />
  );
}
