import CustomizeToolTip from '@src/components/atoms/tooltip';
import Info from '@src/components/icons/info';
import { toolTipDescription } from '@src/constants';
import { useGetDRepStatsQuery } from '@src/store/drep/api';
import { formatAda, lovelaceToAda } from '@src/utils/dRepUtils';

interface StatisticsItemProps {
  label: string;
  value: string;
  valueStyle: string;
  hasIcon: boolean;
}
export default function StatisticsCard({ DRepId }: { DRepId: string }) {
  const { data: dRepStats, isLoading: isLoading } = useGetDRepStatsQuery(
    DRepId,
    {
      skip: !DRepId,
    }
  );

  function StatisticItem({
    label,
    value,
    valueStyle,
    hasIcon,
  }: StatisticsItemProps) {
    const staticsToolTipDescription =
      label === 'Live Voting Power'
        ? toolTipDescription.liveVotingPower
        : toolTipDescription.influencePower;

    return (
      <div className="flex flex-col">
        <div className="body14 flex gap-2 font-medium dark:text-gray-400">
          <span>{label}</span>
          {hasIcon && (
            <>
              <Info
                className="z-20 cursor-pointer"
                data-tooltip-place="right-start"
                data-tooltip-id={label}
              />
              <CustomizeToolTip
                id={label}
                className="!max-w-[172px]"
                description={staticsToolTipDescription}
              />
            </>
          )}
        </div>
        <p className={`body20 font-semibold ${valueStyle}`}>{value}</p>
      </div>
    );
  }

  return (
    <div className="min-w-[288px] rounded-lg border dark:border-dark-neutral-700 dark:bg-dark-neutral-900">
      <div className="body18 h-[51px] rounded-t-lg border-b px-4 py-3 font-semibold dark:border-dark-neutral-700 dark:bg-dark-neutral-800 dark:text-white">
        Statistics
      </div>
      {isLoading ? (
        <div className="h-[297px] w-full animate-pulse rounded-b-md bg-neutral-700" />
      ) : (
        <div className="flex flex-col gap-4 p-4">
          <StatisticItem
            label="Live Voting Power"
            value={formatAda(lovelaceToAda(dRepStats?.liveVotingPower || 0))}
            valueStyle="dark:text-white"
            hasIcon={true}
          />
          <StatisticItem
            label="Influence Power"
            value={dRepStats?.influence || '0'}
            valueStyle="dark:text-semantic-success-600"
            hasIcon={true}
          />
          <StatisticItem
            label="Delegators"
            value={dRepStats?.liveDelegators.toString() || '0'}
            valueStyle="dark:text-white"
            hasIcon={false}
          />
          <div className="h-[0.5px] w-full bg-statistics-section-gradent"></div>
          <div className="flex flex-wrap justify-between gap-4">
            <StatisticItem
              label="Voted"
              value={dRepStats?.voted.toString() || '0'}
              valueStyle="dark:text-semantic-success-600"
              hasIcon={false}
            />
            <StatisticItem
              label="Not Voted"
              value={dRepStats?.notVoted.toString() || '0'}
              valueStyle="dark:text-semantic-error-600"
              hasIcon={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}
