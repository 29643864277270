import { toast } from 'react-toastify';
import { ToastId } from '@src/constants/toastId';
import cn from 'classnames';

interface HoverCopyWrapperProps {
  value: string;
  children: React.ReactElement | string;
  className?: string;
}

export default function HoverCopyWrapper({
  value,
  children,
  className,
}: HoverCopyWrapperProps) {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    toast.success('Copied to clipboard', { toastId: ToastId.SUCCESS_TOAST });
  };
  return (
    <span onClick={handleCopy} className={cn('cursor-pointer', className)}>
      {children}
    </span>
  );
}
