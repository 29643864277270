import { formatAda, lovelaceToAda } from '@src/utils/dRepUtils';

interface IVote {
  lovelaceBalance: number;
  label: string;
}
export default function Vote({ lovelaceBalance, label }: IVote) {
  return (
    <div className="flex flex-col items-start xl:items-end">
      <p className="body14 font-medium text-neutral-600 dark:text-white">
        {label}
      </p>
      <p className="h24 font-bold dark:text-gray-100">
        ₳ {formatAda(lovelaceToAda(lovelaceBalance))}
      </p>
    </div>
  );
}
