import LinksCard from '@src/components/cards/linksCard';
import StatisticsCard from '@src/components/cards/statistics';
import type { IMetadata } from '@src/models/dtos/metadata';

export default function DRepProfileRightContainer({
  metadata,
  DRepId,
}: {
  metadata: IMetadata | null;
  DRepId: string;
}) {
  return (
    <div className="flex w-full flex-col gap-6 sm:w-[288px]">
      <StatisticsCard DRepId={DRepId} />
      {metadata &&
        metadata.body.references &&
        metadata.body.references.length > 0 && (
          <LinksCard references={metadata.body.references} />
        )}
    </div>
  );
}
