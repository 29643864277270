import Facebook from '@src/components/icons/socials/facebook';
import Github from '@src/components/icons/socials/github';
import Instagram from '@src/components/icons/socials/instagram';
import LinkedIn from '@src/components/icons/socials/linkedin';
import Twitter from '@src/components/icons/socials/twitter';
import Web from '@src/components/icons/socials/web';
import type { ReferenceWithId } from '@src/models/dtos/metadata';
import { isEmpty } from 'lodash';

const knownSocials = [
  'discord',
  'facebook',
  'github',
  'mail',
  'instagram',
  'linkedin',
  'reddit',
  't.me',
  'x.com',
];

export const determineIcon = (link: string): string => {
  return knownSocials.find((social) => link.includes(social)) ?? 'web';
};

export const getIcon = (link: string) => {
  const social = determineIcon(link);
  switch (social) {
    case 'x.com':
      return Twitter;
    case 'linkedin':
      return LinkedIn;
    case 'instagram':
      return Instagram;
    case 'facebook':
      return Facebook;
    case 'github':
      return Github;
    default:
      return Web;
  }
};

export type socialType =
  | 'discord'
  | 'facebook'
  | 'github'
  | 'instagram'
  | 'linkedin'
  | 'reddit'
  | 't.me'
  | 'x.com'
  | 'mail';

export const getUserName = (
  link: string,
  social: socialType
): string | null => {
  const extractUserName = (delimiter: string): string =>
    link.split(delimiter).pop()?.split('/')[0] || '';

  switch (social.toLowerCase()) {
    case 'discord':
    case 'github':
    case 'x.com':
      return '@' + extractUserName('/');

    case 'instagram':
    case 'facebook':
      return '@' + extractUserName('.com/');
    case 'linkedin':
      return '@' + extractUserName('/in/');
    case 'reddit':
      return '@' + extractUserName('/user/');
    case 't.me':
      return '@' + extractUserName('t.me/');

    case 'mail':
    default:
      return null;
  }
};

export const getSocialPrefix = (social: socialType) => {
  switch (social) {
    case 'x.com':
      return 'https://x.com/';
    case 'linkedin':
      return 'https://www.linkedin.com/in/';
    case 'instagram':
      return 'https://www.instagram.com/';
    case 'facebook':
      return 'https://www.facebook.com/';
    default:
      return '';
  }
};

const knownSocial: socialType[] = [
  'x.com',
  'linkedin',
  'instagram',
  'facebook',
];
export const filterEmptyLinks = (
  references?: ReferenceWithId[]
): ReferenceWithId[] => {
  if (!references) return [];

  return references.filter((link) => {
    const isPortfolioWithoutUri =
      link.label.toString().toLowerCase() === 'portfolio' && isEmpty(link.uri);

    const isEmptyLink = isEmpty(link.label) && isEmpty(link.uri);

    const hasKnownSocialPrefix = knownSocial.some(
      (prefix) => link.uri === getSocialPrefix(prefix)
    );

    return !isPortfolioWithoutUri && !isEmptyLink && !hasKnownSocialPrefix;
  });
};
