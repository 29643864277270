import type { Reference } from '@src/models/dtos/metadata';
import { isEmpty } from 'lodash';
import {
  getFieldValue,
  getReferenceDescription,
} from '@src/utils/metadataUtils';
import SocialNav from '@src/components/molecules/SocialNav';
import type { IDetails } from '@src/models/types';

interface LinksCardProps {
  references?: Reference[];
}

export default function LinksCard({ references }: LinksCardProps) {
  const othersReferenceDescription: IDetails[] = references
    ? references
        .filter(
          (reference) =>
            reference['@type'] !== 'Link' && reference['@type'] !== 'Identity'
        )
        .map((reference) => {
          return {
            label: !isEmpty(getFieldValue(reference.label))
              ? getFieldValue(reference.label)
              : 'Missing label',
            className: isEmpty(getFieldValue(reference.label))
              ? '!text-error-600'
              : '',
            value: getFieldValue(reference.uri),
          };
        })
    : [];
  return (
    <div className="rounded-lg border dark:border-dark-neutral-700 dark:bg-dark-neutral-900">
      <div className="body18 h-[51px] rounded-t-lg border-b px-4 py-3 font-semibold dark:border-dark-neutral-700 dark:bg-dark-neutral-800 dark:text-white">
        Links
      </div>
      <div className="flex flex-col p-4">
        <SocialNav
          links={getReferenceDescription('Link', references).concat(
            getReferenceDescription('Identity', references),
            othersReferenceDescription
          )}
        />
      </div>
    </div>
  );
}
