export function getNetworkNameFromURL(url: string): string {
  const validNetworks = new Set(['preview', 'preprod', 'sancho']);
  const network = url.split('//')[1]?.split('.')[0] ?? '';
  return validNetworks.has(network) ? network.toUpperCase() : 'MAINNET';
}

export function determineExternalExplorerLink(network: string): string {
  switch (network) {
    case 'MAINNET':
      return 'https://cardanoscan.io';
    case 'PREVIEW':
      return 'https://preview.cardanoscan.io';
    case 'PREPROD':
      return 'https://preprod.cardanoscan.io';
    case 'SANCHONET':
    case 'SANCHO':
      return 'https://sanchonet.cexplorer.io';
    default:
      return '';
  }
}
