import { determineIcon, getIcon, getUserName } from '@src/utils/socialHelpers';
import ExternalLink from '@src/components/icons/externalLink';
import AnchorLink from '@src/components/atoms/links/anchor-link';
import React from 'react';
import type { IDetails } from '@src/models/types';

interface SocialNavProps {
  links: IDetails[];
}

export default function SocialNav({ links }: SocialNavProps) {
  return (
    <div className="flex w-fit flex-wrap gap-4">
      {links.map((link, index) => {
        const linkValue = link.value?.toString() || '';
        const socialIcon = determineIcon(linkValue);
        //@ts-ignore
        const userName = getUserName(linkValue, socialIcon);

        return (
          <div key={index} className="flex w-full items-center justify-between">
            <div className="body16 flex items-center gap-3 font-medium">
              {React.createElement(getIcon(linkValue), {
                className: 'h-5 w-5 dark:text-white',
              })}

              {userName ? (
                <span className="max-w-[100px] flex-nowrap truncate text-nowrap dark:text-gray-400">
                  {userName}
                </span>
              ) : (
                <span className="max-w-[100px] truncate lowercase dark:text-gray-400">
                  {link.label}
                </span>
              )}
            </div>
            <AnchorLink href={linkValue} target="_blank">
              <ExternalLink className="h-4 w-4 dark:text-white" />
            </AnchorLink>
          </div>
        );
      })}
    </div>
  );
}
