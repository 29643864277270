import Tabs from '@src/components/atoms/tabs';
import type { IDRepFormField } from '@src/models/dtos/metadata';
import ProfileTab from '@src/components/molecules/edit-profile-tabs/profileTab';
import AboutYourselfTab from '@src/components/molecules/edit-profile-tabs/aboutYourselfTab';
import PaymentAddressTab from '@src/components/molecules/edit-profile-tabs/paymentAddressTab';
import SocialLinksTab from '@src/components/molecules/edit-profile-tabs/socialLinksTab';
import IdentityLinksTab from '@src/components/molecules/edit-profile-tabs/identityLinksTab';
import React from 'react';
import { useTabs } from '@src/lib/hooks/use-tabs';
import { useFormHandlers } from '@src/lib/hooks/use-form-handler';
import Button from '@src/components/atoms/buttons/button';

interface DRepProfileTabsProps {
  dRepRegistrationFormData: IDRepFormField;
  setDRepRegistrationFormData: (
    value: (prev: IDRepFormField) => IDRepFormField
  ) => void;
  changesValue: Record<string, any> | null;
}

export default function EditProfileTabs({
  dRepRegistrationFormData,
  setDRepRegistrationFormData,
  changesValue,
}: DRepProfileTabsProps) {
  const { activeIndex, setActiveIndex, dRepTabs, handleNextStep } =
    useTabs(changesValue);
  const { handleOnChange } = useFormHandlers(
    dRepRegistrationFormData,
    setDRepRegistrationFormData
  );

  const renderTabComponent = ({ value }: { value: string }) => {
    switch (value) {
      case 'Profile':
        return (
          <ProfileTab
            handleNext={handleNextStep}
            setDRepRegistrationFormData={setDRepRegistrationFormData}
            dRepRegistrationFormData={dRepRegistrationFormData}
          />
        );
      case 'About yourself':
        return (
          <AboutYourselfTab
            onChange={handleOnChange}
            handleNext={handleNextStep}
            dRepRegistrationFormData={dRepRegistrationFormData}
          />
        );
      case 'Payment Address':
        return (
          <PaymentAddressTab
            dRepRegistrationFormData={dRepRegistrationFormData}
            setDRepRegistrationFormData={setDRepRegistrationFormData}
            handleNext={handleNextStep}
          />
        );
      case 'Social Links':
        return (
          <SocialLinksTab
            dRepRegistrationFormData={dRepRegistrationFormData}
            setDRepRegistrationFormData={setDRepRegistrationFormData}
            handleNext={handleNextStep}
          />
        );
      case 'Idenity':
        return (
          <IdentityLinksTab
            dRepRegistrationFormData={dRepRegistrationFormData}
            setDRepRegistrationFormData={setDRepRegistrationFormData}
            handleNext={handleNextStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="rounded-xl border dark:border-dark-neutral-700 dark:bg-dark-neutral-900 md:w-fit">
        <Tabs
          currentActiveIndex={activeIndex}
          items={dRepTabs}
          onChange={(index) => setActiveIndex(index)}
        />
      </div>
      <div className="flex w-full flex-col gap-5 rounded-xl border p-8 dark:border-dark-neutral-700 dark:bg-dark-neutral-900">
        {renderTabComponent({ value: dRepTabs[activeIndex].value })}
        <Button
          buttonSize="large"
          onClick={handleNextStep}
          className="flex w-full justify-center md:hidden"
        >
          Next
        </Button>
      </div>
    </>
  );
}
