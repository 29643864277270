import InputField from '@src/components/atoms/textfield/inputField';
import TabComponentLeftContent from '@src/components/molecules/edit-profile-tabs/tabComponentleftContent';
import LinkedIn from '@src/components/icons/socials/linkedin';
import Facebook from '@src/components/icons/socials/facebook';
import Instagram from '@src/components/icons/socials/instagram';
import type {
  IDRepFormField,
  ReferenceWithId,
} from '@src/models/dtos/metadata';
import cn from 'classnames';
import Twitter from '@src/components/icons/socials/twitter';
import Button from '@src/components/atoms/buttons/button';
import Plus from '@src/components/icons/plus';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { isValidURLFormat } from '@src/utils/validationUtils';
import { getFieldValue } from '@src/utils/metadataUtils';
import { isEmpty } from 'lodash';
import DeleteIcon from '@src/components/icons/delete';
import type { socialType } from '@src/utils/socialHelpers';
import { getSocialPrefix, getUserName } from '@src/utils/socialHelpers';
import { useFormHandlers } from '@src/lib/hooks/use-form-handler';

export interface SocialLinksTabProps {
  setDRepRegistrationFormData: (
    value: (prev: IDRepFormField) => IDRepFormField
  ) => void;
  dRepRegistrationFormData: IDRepFormField;
  handleNext?: () => void;
}

const SOCIAL_MEDIA_ICONS = {
  'x.com': Twitter,
  linkedIn: LinkedIn,
  instagram: Instagram,
  facebook: Facebook,
};

export default function SocialLinksTab({
  setDRepRegistrationFormData,
  dRepRegistrationFormData,
  handleNext,
}: SocialLinksTabProps) {
  const { socialLinksError, setSocialLinksError, getLinks } = useFormHandlers(
    dRepRegistrationFormData,
    setDRepRegistrationFormData
  );
  const [links, setLinks] = useState<Array<ReferenceWithId>>(getLinks('Link'));

  const getSocialMediaLink = (platform: string) => {
    return links.find((link) =>
      link.uri.toString().includes(platform.toLowerCase())
    );
  };

  const [xLink, setXLink] = useState(getSocialMediaLink('x.com'));
  const [linkedInLink, setLinkedInLink] = useState(
    getSocialMediaLink('linkedIn')
  );
  const [instagramLink, setInstagramLink] = useState(
    getSocialMediaLink('instagram')
  );
  const [facebookLink, setFacebookLink] = useState(
    getSocialMediaLink('facebook')
  );

  const updateDRepRegistrationValue = (updatedLink: Array<ReferenceWithId>) => {
    setDRepRegistrationFormData((prevState: IDRepFormField) => ({
      ...prevState,
      references: [
        ...(prevState.references?.filter((ref) => ref['@type'] !== 'Link') ||
          []),
        ...updatedLink,
      ],
    }));
  };

  const updateErrorState = (uuid: string, message?: string) => {
    const currentErrors = Array.isArray(socialLinksError?.errors)
      ? socialLinksError.errors
      : [];

    const updatedErrors = message
      ? [
          ...currentErrors.filter((err) => err.id !== uuid),
          { id: uuid, message },
        ]
      : currentErrors.filter((err) => err.id !== uuid);

    setSocialLinksError({
      step: 3,
      errors: updatedErrors,
    });
  };

  const handleOnDelete = (id: string) => {
    updateErrorState(id, '');
    const updatedLink = links.filter((link) => link.id !== id);
    setLinks(updatedLink);
    updateDRepRegistrationValue(updatedLink);
  };

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    { uuid, isKnownLinks = false }: { uuid: string; isKnownLinks?: boolean }
  ) => {
    const { id, value } = e.target;

    const updateErrorIfNeeded = (id: string, value: string, uuid: string) => {
      if (id === 'uri') {
        const errorMessage = isValidURLFormat(value)
          ? undefined
          : 'Invalid URL.';
        updateErrorState(uuid, errorMessage);
      }
    };

    const updateLink = ({
      link,
      social,
    }: {
      link: ReferenceWithId;
      social?: socialType;
    }): ReferenceWithId => {
      if (link.id === uuid) {
        const updatedValue = social
          ? getSocialPrefix(social) + value.replace(getSocialPrefix(social), '')
          : value;
        updateErrorIfNeeded(id, updatedValue, uuid);
        return {
          ...link,
          [id]: updatedValue,
        };
      }

      return link;
    };

    const updateKnownLinks = (): ReferenceWithId[] => {
      const updatedKnownLinks = [
        xLink ? updateLink({ link: xLink, social: 'x.com' }) : null,
        linkedInLink
          ? updateLink({ link: linkedInLink, social: 'linkedin' })
          : null,
        instagramLink
          ? updateLink({ link: instagramLink, social: 'instagram' })
          : null,
        facebookLink
          ? updateLink({ link: facebookLink, social: 'facebook' })
          : null,
      ].filter((link): link is ReferenceWithId => link !== null);

      // Update the individual state for known links
      if (xLink)
        setXLink(updatedKnownLinks.find((link) => link.id === xLink.id));
      if (linkedInLink)
        setLinkedInLink(
          updatedKnownLinks.find((link) => link.id === linkedInLink.id)
        );
      if (instagramLink)
        setInstagramLink(
          updatedKnownLinks.find((link) => link.id === instagramLink.id)
        );
      if (facebookLink)
        setFacebookLink(
          updatedKnownLinks.find((link) => link.id === facebookLink.id)
        );

      // Ensure updated links include all known links
      const knownLinksIds = updatedKnownLinks.map((link) => link.id);
      return links
        .map((link) =>
          knownLinksIds.includes(link.id)
            ? updatedKnownLinks.find((knownLink) => knownLink.id === link.id)!
            : link
        )
        .concat(
          updatedKnownLinks.filter(
            (knownLink) => !links.some((link) => link.id === knownLink.id)
          )
        );
    };

    const updatedLinks = isKnownLinks
      ? updateKnownLinks()
      : links.map((link) => updateLink({ link }));

    setLinks(updatedLinks);
    updateDRepRegistrationValue(updatedLinks);
  };

  const handleErrorMessage = (identity: string) => {
    if (socialLinksError && socialLinksError.errors.length !== 0) {
      const filterCurrentError = socialLinksError.errors.filter(
        (error) => error.id === identity
      );
      if (filterCurrentError.length !== 0) {
        return filterCurrentError[0].message;
      }
    }
    return '';
  };

  const socialLinks = ['x.com', 'linkedIn', 'instagram', 'facebook'].map(
    (platform) => {
      if (platform === 'x.com') {
        return {
          title: 'X/Twitter',
          platform: platform as socialType,
          link: getSocialMediaLink(platform),
        };
      }
      return {
        title: platform,
        platform: platform as socialType,
        link: getSocialMediaLink(platform),
      };
    }
  );

  const filteredLinks = links.filter(
    (link) => !socialLinks.some((social) => social.link?.id === link.id)
  );
  useEffect(() => {
    if (xLink == undefined) {
      setXLink({
        id: uuid(),
        '@type': 'Link',
        label: 'X/Twitter',
        uri: getSocialPrefix('x.com'),
      });
    }
    if (linkedInLink == undefined) {
      setLinkedInLink({
        id: uuid(),
        '@type': 'Link',
        label: 'LinkedIn',
        uri: getSocialPrefix('linkedin'),
      });
    }
    if (instagramLink == undefined) {
      setInstagramLink({
        id: uuid(),
        '@type': 'Link',
        label: 'Instagram',
        uri: getSocialPrefix('instagram'),
      });
    }
    if (facebookLink == undefined) {
      setFacebookLink({
        id: uuid(),
        '@type': 'Link',
        label: 'Facebook',
        uri: getSocialPrefix('facebook'),
      });
    }
    if (filteredLinks.length === 0)
      setLinks([...links, { id: uuid(), '@type': 'Link', label: '', uri: '' }]);
  }, []);

  const linkData =
    filteredLinks.length > 0
      ? filteredLinks
      : [{ id: uuid(), '@type': 'Link', label: '', uri: '' }];

  const handleAddLink = () => {
    const updatedLink = [
      ...links,
      { id: uuid(), '@type': 'Link', label: '', uri: '' },
    ];
    setLinks(updatedLink);
  };

  return (
    <div className="flex flex-col gap-5 md:flex-row md:gap-14">
      <TabComponentLeftContent
        handleNext={handleNext}
        title="Social Links"
        description="Link your social media accounts to stay connected and share your updates with your network."
      />
      <div className="flex w-full flex-col gap-5">
        {socialLinks.map(({ platform, link, title }) => {
          const Icon =
            SOCIAL_MEDIA_ICONS[platform as keyof typeof SOCIAL_MEDIA_ICONS];
          return (
            <InputField
              key={platform}
              onChange={(e) =>
                handleOnChange(e, { uuid: link?.id || '', isKnownLinks: true })
              }
              id="uri"
              value={
                getUserName(getFieldValue(link?.uri) || '', platform)?.replace(
                  '@',
                  ''
                ) || ''
              }
              preActions={[
                <Icon key={platform} className="h-5 w-5 dark:text-white" />,
              ]}
              label={title.charAt(0).toUpperCase() + title.slice(1)}
              placeholder="johndoe_112"
            />
          );
        })}
        {linkData.map((link) => {
          return (
            <div
              key={link.id}
              className="flex w-full flex-col justify-between gap-6 md:flex-row"
            >
              <InputField
                id="label"
                className="w-full"
                onChange={(e) => handleOnChange(e, { uuid: link.id })}
                value={getFieldValue(link.label)}
                label="Description/Title"
                placeholder="eg. Github"
              />
              <div
                className={cn(
                  'flex w-full gap-6',
                  handleErrorMessage(link.id) ? 'items-center' : 'items-end'
                )}
              >
                <InputField
                  id="uri"
                  className="w-full"
                  value={getFieldValue(link.uri)}
                  onChange={(e) => handleOnChange(e, { uuid: link.id })}
                  helperText={handleErrorMessage(link.id)}
                  variant={
                    !isEmpty(handleErrorMessage(link.id)) ? 'error' : 'neutral'
                  }
                  label="Url"
                  placeholder="eg. https://github.com/johndoe"
                />

                {linkData.length > 1 && (
                  <div
                    onClick={() => handleOnDelete(link.id)}
                    className="flex h-11 w-11 cursor-pointer items-center justify-center rounded-xl border dark:border-dark-neutral-700 dark:bg-dark-neutral-600"
                  >
                    <DeleteIcon className="h-6 w-6 text-semantic-error-600" />
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {links.length < 8 && (
          <Button onClick={handleAddLink} className="w-fit" variant="gray">
            <span>Add detail</span> <Plus />
          </Button>
        )}
      </div>
    </div>
  );
}
