import { middleTruncate } from '@src/utils/stringUtils';
import HoverCopyWrapper from '@src/components/atoms/HoverCopyWrapper';
import ContentCard from '@src/components/cards/contentCard';
import { formatAda } from '@src/utils/dRepUtils';
import type { IDRepDelegators } from '@src/store/drep/types';
import { seperateDateTime, timeAgo } from '@src/utils/date';
import EmptyContent from '@src/components/cards/emptyContentCard';
import { noContentDescription } from '@src/constants';
import AnchorLink from '../atoms/links/anchor-link';
import ExternalLink from '../icons/externalLink';
import environments from '@src/configs/environments';
import { getNetworkNameFromURL } from '@src/utils/network';
import { determineExternalExplorerLink } from '@src/utils/network';

interface DRepDelegatorsProps {
  delegators: IDRepDelegators[];
  isOwner: boolean;
}

export default function ActiveDRepDelegators({
  delegators,
  isOwner,
}: DRepDelegatorsProps) {
  if (delegators.length === 0)
    return (
      <EmptyContent
        description={
          isOwner ? noContentDescription.owner.other : noContentDescription.user
        }
      />
    );
  return (
    <ContentCard>
      <table className="w-full rounded-lg border dark:border-dark-neutral-700">
        <thead>
          <tr>
            <th className="min-w-[130px] md:min-w-[250px] xl:min-w-[381px]">
              Account
            </th>
            <th className="min-w-[99px]">Active Stake</th>
            <th className="min-w-[144px]">Transaction</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {delegators.map((delegator, index) => {
            const dateTime = seperateDateTime(
              delegator.delegatedAt?.time?.toString() || ''
            );
            return (
              <tr key={index}>
                <td>
                  {delegator?.stakeAddress && (
                    <HoverCopyWrapper
                      className="label12 font-medium text-gray-600 dark:text-white"
                      value={delegator.stakeAddress}
                    >
                      {middleTruncate(delegator.stakeAddress, 32)}
                    </HoverCopyWrapper>
                  )}
                </td>
                <td className="label12 font-medium dark:text-white">
                  {`₳ ${formatAda(parseInt(delegator.amount || '0') / 1000000)}`}
                </td>
                <td className="flex flex-col items-start">
                  {/* Transaction Date*/}
                  <div className="relative-time">
                    {timeAgo(delegator.delegatedAt.time)}
                  </div>
                  <div className="transaction-date">
                    {dateTime.date} | {dateTime.time}
                  </div>
                </td>
                <td>
                  <AnchorLink
                    href={`${determineExternalExplorerLink(
                      environments.CARDANO_NETWORK ||
                        (typeof window !== 'undefined'
                          ? getNetworkNameFromURL(window.location.href)
                          : 'MAINNET')
                    )}/transaction/${delegator.delegatedAt.txId}`}
                    target="new"
                  >
                    <ExternalLink className="dark:text-white" />
                  </AnchorLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </ContentCard>
  );
}
