import type { BadgeColor } from '@src/components/atoms/badges/badge';
import Badge from '@src/components/atoms/badges/badge';
export default function VoteBadge({ vote }: { vote: string }) {
  function getVoteColor(vote: string): BadgeColor {
    switch (vote) {
      case 'Yes':
        return 'success';
      case 'No':
        return 'error';
      case 'Abstain':
        return 'warning';
      default:
        return 'information';
    }
  }
  return (
    <Badge
      className="w-fit"
      label={vote}
      variant="light"
      color={getVoteColor(vote)}
    ></Badge>
  );
}
