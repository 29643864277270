'use client';
import FiveOThree from '@src/app/503';
import FourOFour from '@src/app/not-found';
import type { IDRepDetails } from '@src/models/dtos/metadata';
import { getDRepDetails } from '@src/utils/dRepUtils';
import { useEffect, useState } from 'react';
import EditProfileContainer from '@src/container/editProfileContainer';
import DRepProfileContainer from '@src/container/dRepProfileContainer';

type dRepStatus = 'editDRep' | 'registerDRep' | 'viewDRep';

interface ProfileWrapperProps {
  dRepId?: string;
  dRepName?: string;
  dRepStatus: dRepStatus;
}
export default function ProfileWrapper({
  dRepId,
  dRepName,
  dRepStatus,
}: ProfileWrapperProps) {
  const [dRepDetails, setDRepDetails] = useState<IDRepDetails>({
    dRep: null,
    token: null,
    metadata: null,
  });
  const [isDRepInformationFetching, setDRepInformationFetching] =
    useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    const fetchDRepDetails = async () => {
      setDRepInformationFetching(true);
      let dRepDetailsResponse: IDRepDetails = dRepDetails;
      if (dRepName) {
        dRepDetailsResponse = await getDRepDetails({
          dRepName: dRepName,
          isServersideFetch: false,
        });
      } else if (dRepId) {
        dRepDetailsResponse = await getDRepDetails({
          dRepId: dRepId,
          isServersideFetch: false,
        });
      }

      setDRepDetails(dRepDetailsResponse);
      setDRepInformationFetching(false);
    };
    fetchDRepDetails();
  }, []);

  if (!isMounted || isDRepInformationFetching) {
    return (
      <div className="flex min-h-calc-85 w-full items-center justify-center bg-white dark:bg-dark-neutral-950">
        <div className="h-14 w-14 animate-spin rounded-full border-b-2 border-primary-900 dark:border-white"></div>
      </div>
    );
  }
  console.log(dRepDetails);

  if (dRepDetails.errorCode === 503) return <FiveOThree />;

  const isDRepRegistered = dRepDetails.dRep
    ? dRepDetails.dRep.status !== 'Retired'
    : false;

  if (
    (!dRepDetails.dRep && !dRepDetails.token) ||
    (dRepStatus === 'editDRep' && !isDRepRegistered) ||
    (dRepStatus === 'registerDRep' && isDRepRegistered)
  ) {
    return <FourOFour />;
  }

  if (dRepStatus === 'editDRep') {
    return (
      <EditProfileContainer
        metadata={dRepDetails.metadata}
        token={dRepDetails.token}
      />
    );
  } else if (dRepStatus === 'registerDRep') {
    return (
      <EditProfileContainer
        metadata={dRepDetails.metadata}
        token={dRepDetails.token}
        isEditDRep={false}
      />
    );
  }
  return (
    <DRepProfileContainer dRepDetails={dRepDetails} fromDRepId={!!dRepId} />
  );
}
