import type { IError } from '@src/models/dtos';
import type {
  IDRepFormField,
  ReferenceWithId,
} from '@src/models/dtos/metadata';
import {
  setIdentityLinksError,
  setPaymentAddressError,
  setSocialLinksError,
} from '@src/store/drep/dRepRegistrationFormErrorSlice';
import { useAppDispatch } from '@src/store/hooks';
import type { RootState } from '@src/store/store';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

export const useFormHandlers = (
  dRepRegistrationFormData: IDRepFormField,
  setDRepRegistrationFormData: (
    value: (prev: IDRepFormField) => IDRepFormField
  ) => void
) => {
  const dispatch = useAppDispatch();
  const { identityLinksError, socialLinksError, paymentAddressError } =
    useSelector((state: RootState) => state.dRepFormError);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setDRepRegistrationFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const getLinks = (type: string): ReferenceWithId[] => {
    const links =
      type === 'Link'
        ? dRepRegistrationFormData.references?.filter(
            (ref) => ref['@type'] !== 'Identity'
          ) || []
        : dRepRegistrationFormData.references?.filter(
            (ref) => ref['@type'] !== 'Link'
          ) || [];

    return links.length === 0
      ? [{ id: uuid(), '@type': type, label: '', uri: '' }]
      : links;
  };

  return {
    handleOnChange,
    getLinks,
    identityLinksError,
    setIdentityLinksError: (error: IError) =>
      dispatch(setIdentityLinksError(error)),
    socialLinksError,
    setSocialLinksError: (error: IError) =>
      dispatch(setSocialLinksError(error)),
    paymentAddressError,
    setPaymentAddressError: (error: string) =>
      dispatch(setPaymentAddressError(error)),
  };
};
