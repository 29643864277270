'use client';

import type { IDRepDetails } from '@src/models/dtos/metadata';
import { useSelector } from 'react-redux';
import type { RootState } from '@src/store/store';
import { useEffect, useState } from 'react';
import { useLazyGetTransactionStatusQuery } from '@src/store/transaction/api';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import {
  resetDelegatePendingTransaction,
  resetDRepRegistrationPendingTransaction,
  resetDRepRetirePendingTransaction,
  selectPendingTransactions,
} from '@src/store/transaction/transaction';
import React from 'react';
import DRepProfileTopContainer from '@src/components/molecules/drep-profile/topContainer';
import DrepProfileTabs from '@src/components/molecules/drep-profile/profileTabs';
import { useParams } from 'next/navigation';
import { useLazyGetDRepByNameQuery } from '@src/store/drep/api';
import { eventEmitter } from '@src/layouts/_layout';

interface IDRepProfile extends IDRepDetails {
  currentDelegatedDRepId: string;
  walletDRepId: string;
  fromDRepId?: boolean;
}

export default function DrepProfileLeftContainer({
  metadata,
  token,
  fromDRepId = false,
  dRep,
  walletDRepId,
  currentDelegatedDRepId,
}: IDRepProfile) {
  const wallet = useSelector((state: RootState) => state.wallet);
  const [fetchTransactionStatus] = useLazyGetTransactionStatusQuery();
  const pendingTransactions = useAppSelector(selectPendingTransactions);
  const [isDelegated, setIsDelegated] = useState(false);
  const dispatch = useAppDispatch();
  const [fetchDRepInformation] = useLazyGetDRepByNameQuery();

  useEffect(() => {
    const checkTransactionStatus = async () => {
      const {
        pendingDelegateTransaction,
        pendingDRepRetireTransaction,
        pendingDRepRegistrationTransaction,
      } = pendingTransactions;

      if (pendingDelegateTransaction.txId) {
        const isExpired =
          pendingDelegateTransaction.time + 4 * 60 * 1000 < Date.now();

        if (isExpired) {
          dispatch(resetDelegatePendingTransaction());
        }

        try {
          const response = await fetchTransactionStatus(
            pendingDelegateTransaction.txId
          );
          if (response.data?.isTransactionConfirmed) {
            dispatch(resetDelegatePendingTransaction());
            setIsDelegated(true);
          }
        } catch (error) {
          console.error('Failed to fetch transaction status', error);
        }
      }

      if (pendingDRepRetireTransaction.txId) {
        const isExpired =
          pendingDRepRetireTransaction.time + 4 * 60 * 1000 < Date.now();

        if (isExpired) {
          dispatch(resetDRepRetirePendingTransaction());
        }

        try {
          const response = await fetchDRepInformation(
            pendingDRepRetireTransaction.name
          ).unwrap();
          if (response.dRep && response.dRep.status === 'Retired') {
            dispatch(resetDRepRetirePendingTransaction());
            window.location.reload();
          }
        } catch (error) {
          console.error(
            'Failed to fetch dRep retirement transaction status',
            error
          );
        }
      }

      if (pendingDRepRegistrationTransaction.txId) {
        const isExpired =
          pendingDRepRegistrationTransaction.time + 4 * 60 * 1000 < Date.now();

        if (isExpired) {
          dispatch(resetDRepRegistrationPendingTransaction());
        }

        try {
          const response = await fetchDRepInformation(
            pendingDRepRegistrationTransaction.name
          ).unwrap();
          const transactionResponse = await fetchTransactionStatus(
            pendingDRepRegistrationTransaction.txId
          ).unwrap();
          if (
            response.dRep &&
            response.dRep.isRegisteredAsDRep &&
            transactionResponse.isTransactionConfirmed
          ) {
            dispatch(resetDRepRegistrationPendingTransaction());
            window.location.reload();
          }
        } catch (error) {
          console.error(
            'Failed to fetch dRep registration transaction status',
            error
          );
        }
      }
    };
    const intervalId = setInterval(checkTransactionStatus, 5000);
    const listener = () => {
      setIsDelegated(false);
    };
    eventEmitter.addEventListener('walletDisconnect', listener);

    return () => {
      clearInterval(intervalId),
        eventEmitter.removeEventListener('walletDisconnect', listener);
    };
  }, [
    pendingTransactions,
    fetchTransactionStatus,
    dispatch,
    wallet.instance,
    fetchDRepInformation,
  ]);

  const params = useParams();
  const queryDrepId = !fromDRepId ? token?.drepId : (params?.dRepId as string);
  const isOwner = token?.drepId === walletDRepId && !fromDRepId;

  return (
    <div className="flex h-full w-full max-w-full flex-col items-center sm:max-w-[400px] md:max-w-[500px] lg:max-w-[600px] xl:max-w-full">
      <div className="relative flex w-full flex-col gap-6">
        <DRepProfileTopContainer
          dRep={dRep}
          currentDelegatedDRepId={currentDelegatedDRepId}
          walletDRepId={walletDRepId}
          metadata={metadata}
          token={token}
          fromDRepId={fromDRepId}
          isDelegated={isDelegated}
        />
        {dRep && (
          <DrepProfileTabs
            metadata={metadata}
            queryDrepId={queryDrepId}
            isOwner={isOwner}
          />
        )}
      </div>
    </div>
  );
}
