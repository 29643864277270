import { middleTruncate } from '@src/utils/stringUtils';
import HoverCopyWrapper from '@src/components/atoms/HoverCopyWrapper';
import ContentCard from '@src/components/cards/contentCard';
import type { IDRepDelegation } from '@src/store/drep/types';
import { seperateDateTime, timeAgo } from '@src/utils/date';
import EmptyContent from '@src/components/cards/emptyContentCard';
import { noContentDescription } from '@src/constants';
import AnchorLink from '../atoms/links/anchor-link';
import ExternalLink from '../icons/externalLink';
import environments from '@src/configs/environments';
import { getNetworkNameFromURL } from '@src/utils/network';
import { determineExternalExplorerLink } from '@src/utils/network';
import DelegationBadge from '../atoms/delegationBadge';

interface DRepdelegationsProps {
  delegations: IDRepDelegation[];
  isOwner: boolean;
}

export default function ActiveDRepdelegations({
  delegations,
  isOwner,
}: DRepdelegationsProps) {
  if (delegations.length === 0)
    return (
      <EmptyContent
        description={
          isOwner ? noContentDescription.owner.other : noContentDescription.user
        }
      />
    );
  return (
    <ContentCard>
      <table className="w-full rounded-lg border dark:border-dark-neutral-700">
        <thead>
          <tr>
            <th className="min-w-[130px] md:min-w-[250px] xl:min-w-[300px]">
              Account
            </th>
            <th className="min-w-[99px]">Action</th>
            <th className="min-w-[40px]">Epoch</th>
            <th className="min-w-[144px]">Transaction</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {delegations.map((delegation, index) => {
            const dateTime = seperateDateTime(
              delegation?.time?.toString() || ''
            );
            return (
              <tr key={index}>
                <td>
                  {delegation?.stakeAddress && (
                    <HoverCopyWrapper
                      className="label12 font-medium text-gray-600 dark:text-white"
                      value={delegation.stakeAddress}
                    >
                      {middleTruncate(delegation.stakeAddress, 32)}
                    </HoverCopyWrapper>
                  )}
                </td>
                <td className="label12 font-medium dark:text-white">
                  <DelegationBadge action={delegation.action} />
                </td>
                <td className="label12 font-medium dark:text-white">
                  {delegation.epochNo}
                </td>
                <td className="flex flex-col items-start">
                  <div className="relative-time">
                    {timeAgo(delegation.time)}
                  </div>
                  <div className="transaction-date">
                    {dateTime.date} | {dateTime.time}
                  </div>
                </td>
                <td>
                  <AnchorLink
                    href={`${determineExternalExplorerLink(
                      environments.CARDANO_NETWORK ||
                        (typeof window !== 'undefined'
                          ? getNetworkNameFromURL(window.location.href)
                          : 'MAINNET')
                    )}/transaction/${delegation.txId}?tab=voteDelegations`}
                    target="new"
                  >
                    <ExternalLink className="dark:text-white" />
                  </AnchorLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </ContentCard>
  );
}
