import Avatar from '@src/components/atoms/avatar';
import Button from '@src/components/atoms/buttons/button';
import PlusCircle from '@src/components/icons/plusCircle';
import cn from 'classnames';
import { useRef } from 'react';

export default function ProfileImageSection({
  selectedImage,
  onImageSelect,
}: {
  selectedImage: File | string | undefined;
  onImageSelect: (image: File | string) => void;
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file, 'file');
      onImageSelect(file);
    }
  };

  const handleImageFileSelection = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    fileInputRef.current?.click();
  };

  const handleImageUploadCancelation = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onImageSelect('');
  };

  return (
    <div className="flex flex-col-reverse gap-10 md:flex-row md:gap-14">
      <div className="flex flex-col">
        <p className="body18 font-bold dark:text-white">Profile photo</p>
        <p className="body14 mb-5 mt-2 dark:text-white">
          This image will be displayed on your profile
        </p>
        <Button
          onClick={handleImageFileSelection}
          variant="gray"
          className="w-fit"
        >
          <span className="body16 font-medium">Change Profile Picture</span>
          <PlusCircle />
        </Button>
      </div>
      <Avatar
        handleDeleteImage={handleImageUploadCancelation}
        className={cn(
          'relative !rounded-full !border-8 !p-0 dark:!border-solid dark:!border-dark-neutral-700'
        )}
        src={
          selectedImage instanceof File
            ? URL.createObjectURL(selectedImage)
            : selectedImage
        }
        size={110}
      />
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  );
}
