import InputField from '@src/components/atoms/textfield/inputField';
import TabComponentLeftContent from '@src/components/molecules/edit-profile-tabs/tabComponentleftContent';
import { useFormHandlers } from '@src/lib/hooks/use-form-handler';
import type { IDRepFormField } from '@src/models/dtos/metadata';
import { isValidReceivingAddress } from '@src/utils/validationUtils';
import { isEmpty } from 'lodash';

export default function PaymentAddressTab({
  dRepRegistrationFormData,
  setDRepRegistrationFormData,
  handleNext,
}: {
  dRepRegistrationFormData: IDRepFormField;
  setDRepRegistrationFormData: (
    value: (prev: IDRepFormField) => IDRepFormField
  ) => void;
  handleNext: () => void;
}) {
  const { handleOnChange, paymentAddressError, setPaymentAddressError } =
    useFormHandlers(dRepRegistrationFormData, setDRepRegistrationFormData);
  return (
    <div className="flex flex-col gap-5 md:flex-row md:gap-14">
      <TabComponentLeftContent
        handleNext={handleNext}
        title="Payment Address"
        description="All of your payment data is securely stored to ensure smooth transactions."
      />

      <div className="w-full">
        <InputField
          id="paymentAddress"
          helperText={paymentAddressError}
          variant={!isEmpty(paymentAddressError) ? 'error' : 'neutral'}
          onChange={(e) => {
            if (!isValidReceivingAddress(e.target.value)) {
              setPaymentAddressError('Invalid payment address.');
            } else {
              setPaymentAddressError('');
            }
            handleOnChange(e);
          }}
          value={dRepRegistrationFormData.paymentAddress}
          className="truncate placeholder:truncate"
          placeholder="eg. addr1q9glnsrt3jzljw3kff8ws70a0n8y2gsjljr9hzmx4f8zpgw4djjw6jkh7lekk7fl0g3w0l8h50c9h5ppgn7kg"
        />
      </div>
    </div>
  );
}
