import React from 'react';

export default function Claim(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.88789 6.46507C2.77842 5.97197 2.79523 5.4592 2.93676 4.97433C3.07828 4.48945 3.33995 4.04816 3.69749 3.69136C4.05503 3.33457 4.49687 3.07383 4.98204 2.93332C5.46722 2.79281 5.98001 2.77707 6.47289 2.88757C6.74417 2.4633 7.11789 2.11414 7.5596 1.87229C8.00131 1.63043 8.4968 1.50366 9.00039 1.50366C9.50398 1.50366 9.99946 1.63043 10.4412 1.87229C10.8829 2.11414 11.2566 2.4633 11.5279 2.88757C12.0215 2.77659 12.5352 2.79225 13.0211 2.93311C13.5071 3.07396 13.9495 3.33543 14.3073 3.69319C14.665 4.05095 14.9265 4.49338 15.0674 4.97932C15.2082 5.46527 15.2239 5.97895 15.1129 6.47257C15.5372 6.74385 15.8863 7.11757 16.1282 7.55929C16.37 8.001 16.4968 8.49648 16.4968 9.00007C16.4968 9.50366 16.37 9.99915 16.1282 10.4409C15.8863 10.8826 15.5372 11.2563 15.1129 11.5276C15.2234 12.0204 15.2077 12.5332 15.0671 13.0184C14.9266 13.5036 14.6659 13.9454 14.3091 14.303C13.9523 14.6605 13.511 14.9222 13.0261 15.0637C12.5413 15.2052 12.0285 15.222 11.5354 15.1126C11.2645 15.5385 10.8904 15.8891 10.448 16.1321C10.0055 16.375 9.50891 16.5024 9.00414 16.5024C8.49937 16.5024 8.00276 16.375 7.56029 16.1321C7.11783 15.8891 6.74382 15.5385 6.47289 15.1126C5.98001 15.2231 5.46722 15.2073 4.98204 15.0668C4.49687 14.9263 4.05503 14.6656 3.69749 14.3088C3.33995 13.952 3.07828 13.5107 2.93676 13.0258C2.79523 12.5409 2.77842 12.0282 2.88789 11.5351C2.46036 11.2645 2.1082 10.8902 1.86418 10.447C1.62015 10.0038 1.49219 9.50603 1.49219 9.00007C1.49219 8.49412 1.62015 7.99638 1.86418 7.55316C2.1082 7.10994 2.46036 6.73564 2.88789 6.46507Z"
        fill="#11A862"
      />
      <path
        d="M6.75 9L8.25 10.5L11.25 7.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
