import React from 'react';

export default function IdCard(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3332 8.33268H14.9998M13.3332 11.666H14.9998M5.14154 12.4994C5.31332 12.0111 5.63246 11.5881 6.05489 11.2889C6.47732 10.9897 6.98222 10.829 7.49987 10.829C8.01753 10.829 8.52243 10.9897 8.94486 11.2889C9.36729 11.5881 9.68642 12.0111 9.85821 12.4994M9.1665 9.16602C9.1665 10.0865 8.42031 10.8327 7.49984 10.8327C6.57936 10.8327 5.83317 10.0865 5.83317 9.16602C5.83317 8.24554 6.57936 7.49935 7.49984 7.49935C8.42031 7.49935 9.1665 8.24554 9.1665 9.16602ZM3.33317 4.16602H16.6665C17.587 4.16602 18.3332 4.91221 18.3332 5.83268V14.166C18.3332 15.0865 17.587 15.8327 16.6665 15.8327H3.33317C2.4127 15.8327 1.6665 15.0865 1.6665 14.166V5.83268C1.6665 4.91221 2.4127 4.16602 3.33317 4.16602Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
