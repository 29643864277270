'use client';
import DrepProfileLeftContainer from '@src/components/molecules/drep-profile/leftContainer';
import DRepProfileRightContainer from '@src/components/molecules/drep-profile/rightContainer';
import type { IDRepDetails } from '@src/models/dtos/metadata';
import { useLazyGetDelegatedDRepQuery } from '@src/store/drep/api';
import type { RootState } from '@src/store/store';
import { getDRepIdFromCip30Wallet } from '@src/utils/dRepUtils';
import { getCip30Wallet } from '@src/utils/kuberUtils';
import withWalletEventListener from '@src/walletEvent/walletStateUtils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'next/navigation';
import { toast } from 'react-toastify';
import { resetMintSuccessfull } from '@src/store/drep/mintSuccessfullSlice';
import { ToastId } from '@src/constants/toastId';

interface DRepProfileContainerProps {
  dRepDetails: IDRepDetails;
  fromDRepId?: boolean;
}

const DRepProfileContainer: React.FC<DRepProfileContainerProps> = ({
  dRepDetails,
  fromDRepId = false,
}) => {
  const wallet = useSelector((state: RootState) => state.wallet);
  const [fetchCurrentDelegationStatus] = useLazyGetDelegatedDRepQuery();
  const [walletDRepId, setWalletDRepId] = useState<string>('');
  const [currentDelegatedDRepId, setCurrentDelegatedDRepId] =
    useState<string>('');
  const { metadata } = dRepDetails;

  const parmas = useParams();

  useEffect(() => {
    const fetchDRepIdAndDelegationStatus = async () => {
      if (!wallet.instance) {
        setCurrentDelegatedDRepId('');
        return;
      }

      try {
        const walletFromKuber = await getCip30Wallet(wallet);
        if (!walletFromKuber) {
          console.error('Error enabling wallet');
          return;
        }

        const stakeAddress =
          (await walletFromKuber.instance.getRewardAddresses())[0] || '';
        const dRepId = (await getDRepIdFromCip30Wallet(walletFromKuber)) || '';
        setWalletDRepId(dRepId);

        const currentDelegationResponse =
          await fetchCurrentDelegationStatus(stakeAddress).unwrap();
        if (currentDelegationResponse) {
          setCurrentDelegatedDRepId(currentDelegationResponse.drep_id);
        }
      } catch (e) {
        console.log('Failed to fetch current delegation status', e);
        setCurrentDelegatedDRepId('none');
      }
    };
    fetchDRepIdAndDelegationStatus();
  }, [wallet, fetchCurrentDelegationStatus]);

  const mintSuccessfull = useSelector(
    (state: RootState) => state.mintSuccessfull
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (mintSuccessfull.value != null) {
      toast.success(
        <div className="flex flex-col gap-2 overflow-hidden">
          <p className="body14 font-semibold text-semantic-success-600">
            Successfully DRep-ID created!
          </p>
          <p className="body14 text-semantic-success-600">
            You have successfully claimed your drep id : &nbsp;
            {mintSuccessfull.value}
          </p>
        </div>,
        { toastId: ToastId.SUCCESS_TOAST }
      );
      dispatch(resetMintSuccessfull());
    }
  }, []);

  return (
    <div className="flex min-h-calc-84 w-full justify-center bg-white py-12 dark:bg-neutral-950">
      <div className="relative flex w-full max-w-[1440px] flex-col justify-center gap-6 px-4 sm:flex-row md:px-[72px] lg:px-[156px]">
        <DrepProfileLeftContainer
          {...dRepDetails}
          walletDRepId={walletDRepId}
          fromDRepId={fromDRepId}
          currentDelegatedDRepId={currentDelegatedDRepId}
        />
        <DRepProfileRightContainer
          metadata={metadata}
          DRepId={
            'dRepId' in parmas
              ? (parmas.dRepId as string)
              : (dRepDetails.token?.drepId as string)
          }
        />
      </div>
    </div>
  );
};

export default withWalletEventListener(DRepProfileContainer);
