import type { ITabOption } from '@src/components/atoms/tabs';
import Tabs from '@src/components/atoms/tabs';
import ActiveDRepDelegators from '@src/components/molecules/ActiveDRepDelegators';
import DRepMetadata from '@src/components/molecules/DRepMetadata';
import DRepRegistrations from '@src/components/molecules/DRepRegistrations';
import DRepVotes from '@src/components/molecules/DRepVotes';
import {
  useGetDRepDelegationsQuery,
  useGetActiveDRepDelegatorsQuery,
  useGetDRepRegistrationsQuery,
  useGetDRepVotesQuery,
} from '@src/store/drep/api';
import { useEffect, useState } from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { MdOutlineHowToVote, MdPeopleOutline } from 'react-icons/md';
import { IoKeyOutline } from 'react-icons/io5';
import type { IMetadata } from '@src/models/dtos/metadata';
import { useRouter } from 'next/navigation';
import { useSearchParams } from 'next/navigation';
import DelegationsIcon from '@src/components/icons/delegationsIcon';
import Delegations from '@src/components/molecules/Delegations';

interface DRepProfileTabsProps {
  queryDrepId: string | undefined;
  isOwner: boolean;
  metadata: IMetadata | null;
}
export default function DrepProfileTabs({
  queryDrepId,
  isOwner,
  metadata,
}: DRepProfileTabsProps) {
  const searchParams = useSearchParams();
  const router = useRouter();

  const dRepTabs: ITabOption[] = [
    {
      value: 'info',
      label: 'DRep info',
      icon: IoIosInformationCircleOutline,
      tabClassName: 'rounded-tl-lg min-w-[117px]',
    },
    {
      value: 'votes',
      label: 'Votes',
      icon: MdOutlineHowToVote,
    },
    {
      value: 'registrations',
      label: 'Registrations',
      icon: IoKeyOutline,
      tabClassName: 'md:rounded-none rounded-tr-lg',
    },
    {
      value: 'active-delegators',
      label: 'Active Delegators',
      icon: MdPeopleOutline,
      tabClassName: 'min-w-[165px]',
    },
    {
      value: 'delegations',
      label: 'Delegations',
      icon: DelegationsIcon,
    },
  ];

  const [activeTab, setActiveTab] = useState<ITabOption>(dRepTabs[0]);

  const { data: dRepVotes } = useGetDRepVotesQuery(queryDrepId || '', {
    skip: queryDrepId === undefined || queryDrepId === null,
  });

  const { data: dRepRegistrations } = useGetDRepRegistrationsQuery(
    queryDrepId || '',
    {
      skip: queryDrepId === undefined || queryDrepId === null,
    }
  );

  const { data: ActiveDRepDelegatorsList } = useGetActiveDRepDelegatorsQuery(
    queryDrepId || '',
    {
      skip: queryDrepId === undefined || queryDrepId === null,
    }
  );

  const { data: dRepDelegations } = useGetDRepDelegationsQuery(
    queryDrepId || '',
    {
      skip: queryDrepId === undefined || queryDrepId === null,
    }
  );

  const TabComponent = ({ value }: { value: string }) => {
    switch (value) {
      case 'info':
        return <DRepMetadata metadata={metadata} isOwner={isOwner} />;
      case 'votes':
        return <DRepVotes votes={dRepVotes || []} isOwner={isOwner} />;
      case 'active-delegators':
        return (
          <ActiveDRepDelegators
            delegators={ActiveDRepDelegatorsList || []}
            isOwner={isOwner}
          />
        );
      case 'registrations':
        return (
          <DRepRegistrations
            registrations={dRepRegistrations || []}
            isOwner={isOwner}
          />
        );
      case 'delegations':
        return (
          <Delegations delegations={dRepDelegations || []} isOwner={isOwner} />
        );
      default:
        return null;
    }
  };

  const updateURLParam = (key: string, value: string) => {
    const currentParams = new URLSearchParams(searchParams.toString());
    currentParams.set(key, value);
    router.replace(`?${currentParams.toString()}`);
  };

  const handleSetActiveTab = (index: number) => {
    setActiveTab(dRepTabs[index]);
    updateURLParam('tab', dRepTabs[index].value);
  };

  useEffect(() => {
    const tab = searchParams.get('tab') || 'info';
    if (tab === 'info') {
      return;
    }
    const drepTab = dRepTabs.find((item) => item?.value === tab);
    drepTab && setActiveTab(drepTab);
    updateURLParam('tab', tab);
  }, []);

  return (
    <div className="rounded-lg border dark:border-dark-neutral-700 dark:bg-dark-neutral-900">
      <Tabs
        className="border-b border-gray-200 dark:border-dark-neutral-700"
        items={dRepTabs}
        onChange={handleSetActiveTab}
        currentActiveIndex={dRepTabs.findIndex(
          (tab) => tab.value === activeTab.value
        )}
      />

      <TabComponent value={activeTab.value} />
    </div>
  );
}
