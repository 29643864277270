import Badge from '@src/components/atoms/badges/badge';
import Button from '@src/components/atoms/buttons/button';
import CopyableField from '@src/components/atoms/copiableField';
import Dropdown from '@src/components/atoms/dropdown/dropdown';
import Vote from '@src/components/atoms/vote';
import CopyIcon from '@src/components/icons/copy';
import EditIcon from '@src/components/icons/edit';
import UserX from '@src/components/icons/userX';
import { useModal } from '@src/components/modal-views/context';
import { ToastId } from '@src/constants/toastId';
import { stakeDelegation } from '@src/lib/kuber-service';
import type { IDRepDetails } from '@src/models/dtos/metadata';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import { getWalletBalance } from '@src/utils/kuberUtils';
import type { RootState } from '@src/store/store';
import {
  selectPendingTransactions,
  setPendingTransactions,
} from '@src/store/transaction/transaction';
import {
  convertDRepToCIP129Format,
  formatAda,
  getImageUrlString,
  getStatusColor,
  lovelaceToAda,
} from '@src/utils/dRepUtils';
import { getCip30Wallet, toCIP105DRep } from '@src/utils/kuberUtils';
import { getFieldValue } from '@src/utils/metadataUtils';
import { isEmptyString } from '@src/utils/stringUtils';
import { isEmpty } from 'lodash';
import { useParams, useRouter } from 'next/navigation';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import cn from 'classnames';
import Image from 'next/image';
import ProfileBackground from '@src/assets/images/profileBackground.jpeg';
import TransactionInProgressBadge from '@src/components/atoms/badges/transactionInProgressBadge';
import AddAvatarIcon from '@src/components/icons/addAvatar';
import { MenuItem } from '@headlessui/react';
import ShareIcon from '@src/components/icons/share';
import Claim from '@src/components/icons/claim';
import DoubleCheck from '@src/components/icons/doubleCheck';
import BadgeCheck from '@src/components/icons/badgeCheck';
import ScriptIcon from '@src/components/icons/scriptsIcon';

interface DRepProfileTopContainerProps extends IDRepDetails {
  currentDelegatedDRepId: string;
  walletDRepId: string;
  fromDRepId?: boolean;
  isDelegated: boolean;
}
export default function DRepProfileTopContainer({
  metadata,
  token,
  fromDRepId = false,
  dRep,
  isDelegated,
  walletDRepId,
  currentDelegatedDRepId,
}: DRepProfileTopContainerProps) {
  const wallet = useSelector((state: RootState) => state.wallet);
  const [isLoading, setIsLoading] = useState(false);
  const pendingTransactions = useAppSelector(selectPendingTransactions);
  const [walletBalance, setWalletBalance] = useState(0);
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const params = useParams();
  const isOwner = token?.drepId === walletDRepId && !fromDRepId;
  const router = useRouter();
  const dRepId = token ? token.drepId : (params?.dRepId as string);
  const isDRepRegistered = dRep ? dRep.status !== 'Retired' : false;

  const isDelegationOrRetirementInProgress = (() => {
    const { pendingDRepRetireTransaction, pendingDelegateTransaction } =
      pendingTransactions;
    const dRepId = fromDRepId ? params.dRepId : token?.drepId;
    return (
      pendingDRepRetireTransaction.name === token?.name ||
      pendingDelegateTransaction.name === dRepId
    );
  })();

  useEffect(() => {
    const fetchWalletBalance = async () => {
      const walletLovelaceBalance = await getWalletBalance(wallet);
      setWalletBalance(parseFloat(walletLovelaceBalance as string));
    };

    if (wallet && wallet.instance) {
      fetchWalletBalance();
    }
  }, [fromDRepId, wallet]);
  const handleDelegateAndRegistration = async () => {
    switch (dRep ? dRep.status : 'NotRegistered') {
      case 'Retired':
      case 'NotRegistered':
        handleRegisterDRep();
        return;
      case 'Active':
      case 'Inactive': {
        setIsLoading(true);
        if (!wallet.instance) {
          openModal('CONNECT_WALLET');
          setIsLoading(false);
          return;
        }

        try {
          const kuberClientWallet = await getCip30Wallet(wallet);
          if (!kuberClientWallet) {
            console.error('Error Enabling Wallet');
          } else {
            const stakeAddress =
              (await kuberClientWallet.instance.getRewardAddresses())[0] || '';

            const { cborHex } = await stakeDelegation(
              kuberClientWallet,
              stakeAddress,
              dRepId
            );

            const signTx = await kuberClientWallet.getSignedTx(cborHex);

            await kuberClientWallet.submitTx(signTx).then((txId) => {
              if (txId) {
                dispatch(
                  setPendingTransactions({
                    ...pendingTransactions,
                    pendingDelegateTransaction: {
                      txId: txId as string,
                      name: dRepId,
                      time: Date.now(),
                    },
                  })
                );
              }
            });
          }
        } catch (e) {
          toast.error(`Failed to delagete due to ${e}`, {
            toastId: ToastId.ERROR_TOAST,
          });
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const getButtonLabel = () => {
    if (dRep && (dRep.status === 'Active' || dRep.status === 'Inactive')) {
      return wallet.instance ? 'Delegate your vote' : 'Connect to delegate';
    } else if (isOwner) {
      return (
        <div className="flex items-center gap-1">
          <AddAvatarIcon className="text-white" />
          {dRep && dRep.status === 'Retired' ? (
            <p> Re-register as a DRep</p>
          ) : (
            <p>Register as a DRep</p>
          )}
        </div>
      );
    }
  };

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
    toast.success('Copied to clipboard', {
      toastId: ToastId.SUCCESS_TOAST,
    });
  };

  // for re-register and register as a dRep
  const handleRegisterDRep = async () => {
    const adaBalance = lovelaceToAda(walletBalance);
    const requiredAda = 500;

    // Check for sufficient balance
    if (adaBalance < requiredAda) {
      const neededAda = requiredAda - adaBalance;
      toast.error(
        `Insufficient balance. You need an additional ${neededAda} ADA.`,
        {
          toastId: ToastId.ERROR_TOAST,
        }
      );
      return;
    }
    router.push(`/${token?.name}/register`);
  };

  const getProfileMenuItem = (): JSX.Element[] => {
    const contentClassname =
      'flex gap-2 dark:hover:bg-dark-neutral-950  hover:bg-gray-100 cursor-pointer px-4 py-[10px] body14 font-medium dark:text-white text-dark-neutral-700 items-center';
    const iconClassname = 'dark:text-white text-dark-neutral-500';

    const renderMenuItem = (
      key: string,
      onClick: () => void,
      icon: JSX.Element,
      label: JSX.Element | string,
      additionalClasses = ''
    ) => (
      <MenuItem key={key}>
        <div
          onClick={onClick}
          className={cn(contentClassname, additionalClasses)}
        >
          {icon}
          {label}
        </div>
      </MenuItem>
    );

    return [
      dRep &&
        !isEmpty(dRep.url) &&
        renderMenuItem(
          'copyUrl',
          () => handleCopy(dRep.url as string),
          <CopyIcon className={iconClassname} />,
          'Copy meta data URL',
          'border-b dark:border-dark-neutral-800 border-gyay-600'
        ),
      renderMenuItem(
        'retireDrep',
        () => openModal('RETIRE_DREP', { tokenName: token?.name }),
        <UserX className={iconClassname} />,
        'Retire as a DRep',
        'border-b  dark:border-dark-neutral-800 border-gyay-600 sm:border-none'
      ),
      renderMenuItem(
        'shareDrep',
        () => openModal('SHARE'),
        <ShareIcon className={iconClassname} />,
        'Share',
        'border-b  dark:border-dark-neutral-800 border-gyay-600 sm:hidden '
      ),
      renderMenuItem(
        'copyDrepId',
        () => handleCopy(dRepId),
        <CopyIcon className={iconClassname} />,
        <span className="max-w-[125px] truncate">{dRepId}</span>,
        'sm:hidden'
      ),
    ].filter(Boolean) as JSX.Element[];
  };

  const getProfileName = () => {
    const givenName = getFieldValue(metadata?.body.givenName);
    return !isEmptyString(givenName)
      ? givenName
      : token?.name
        ? token?.name
        : 'Data Missing';
  };

  const getProfileImage = () => {
    const image = getImageUrlString(metadata?.body?.image);
    return image;
  };

  const renderTokenName = () => {
    if (!fromDRepId)
      return (
        <div className="flex font-medium text-neutral-600 dark:text-gray-400">
          {`@${token?.name}`}
        </div>
      );
    if (
      token === null &&
      !isEmpty(walletDRepId) &&
      ((params?.dRepId as string) === toCIP105DRep(walletDRepId) ||
        (params?.dRepId as string) === walletDRepId)
    )
      return (
        <div className="flex items-center gap-1">
          <Claim className="h-[18px] w-[18px]" />
          <p className="body14 font-medium text-semantic-success-600">
            DRep Token is available!
          </p>
        </div>
      );
  };

  const renderDelegationAndRegistrationButton = () => {
    const hasPendingTransactions =
      !isEmpty(pendingTransactions.pendingDelegateTransaction.txId) ||
      !isEmpty(pendingTransactions.pendingDRepRetireTransaction.txId) ||
      !isEmpty(pendingTransactions.pendingDRepRegistrationTransaction.txId);

    const isDelegateButtonDisabled =
      wallet.instance != null && (isLoading || hasPendingTransactions);

    const shouldShowDelegateButton =
      wallet.instance == null ||
      !isDRepRegistered ||
      (dRep && currentDelegatedDRepId !== toCIP105DRep(dRepId) && !isDelegated);

    const isRetiredTransactionInProgress =
      wallet.instance && isDelegationOrRetirementInProgress;

    if (!isDRepRegistered && !wallet.instance) return null;
    if (isRetiredTransactionInProgress) return <TransactionInProgressBadge />;

    if (wallet.instance && isEmpty(currentDelegatedDRepId))
      return (
        <div className="h-9 w-[138px] animate-pulse rounded-xl bg-neutral-700"></div>
      );

    if (
      wallet.instance &&
      dRep &&
      isDRepRegistered &&
      (currentDelegatedDRepId === toCIP105DRep(dRepId) || isDelegated)
    ) {
      return (
        <div className="flex items-center gap-1 rounded-xl bg-semantic-success-100 px-[10px] py-2">
          <BadgeCheck className="h-[18px] w-[18px]" />
          <p className="body14 font-semibold text-semantic-success-600">
            <span className="font-bold">{`₳ ${formatAda(lovelaceToAda(walletBalance))}`}</span>{' '}
            <span>{` delegated to ${getFieldValue(metadata?.body.givenName || token?.name)}`}</span>
          </p>
        </div>
      );
    }
    if (shouldShowDelegateButton && getButtonLabel())
      return (
        <Button
          onClick={handleDelegateAndRegistration}
          disabled={isDelegateButtonDisabled}
          buttonSize="xsmall"
        >
          {getButtonLabel()}
        </Button>
      );
  };

  return (
    <div className="flex h-full w-full flex-col rounded-lg border dark:border-dark-neutral-700 dark:bg-dark-neutral-900">
      <Image
        src={ProfileBackground}
        alt="profile background"
        className="!h-[160px] w-full rounded-t-lg object-cover"
        fill
      />

      <div className="relative mt-[160px] flex w-full flex-col px-6 pb-6">
        <div className="absolute -top-[66px] flex h-[86px] w-[86px] items-center justify-center rounded-full border-[4px] border-white bg-profile-gradient dark:border-neutral-900">
          {getProfileImage() ? (
            <img
              src={getProfileImage() as string}
              alt="Profile"
              className="h-full w-full rounded-full object-cover"
            />
          ) : (
            <p className="h1 font-semibold text-white">
              {getProfileName()?.slice(0, 1).toUpperCase()}
            </p>
          )}
        </div>

        <div className="mt-8 flex flex-col gap-6 lg:flex-row lg:justify-between">
          <div className="flex flex-col">
            {/* Given name and status */}
            <div className="flex items-center gap-[10px]">
              <h1
                className={cn(
                  'h24 line-clamp-3 max-w-[500px] font-bold dark:text-white',
                  isEmptyString(getFieldValue(metadata?.body.givenName)) &&
                    token === null &&
                    'text-red-500 dark:text-red-500'
                )}
              >
                {getProfileName()}
              </h1>

              <Badge
                label={dRep ? dRep.status : 'NotRegistered'}
                color={getStatusColor(dRep ? dRep.status : 'NotRegistered')}
              />

              {fromDRepId &&
                token === null &&
                walletDRepId &&
                ((params?.dRepId as string) === toCIP105DRep(walletDRepId) ||
                  (params?.dRepId as string) === walletDRepId) && (
                  <div
                    onClick={() => router.push('/drep/mint')}
                    className="label12 flex h-[22px] cursor-pointer items-center gap-[6px] rounded-[6px] bg-orange-600 px-[6px] py-[2px] font-semibold text-white"
                  >
                    <DoubleCheck />
                    <p>Claim Now</p>
                  </div>
                )}
            </div>
            {renderTokenName()}
          </div>
          {dRep && (
            <div className="flex items-center gap-6 md:justify-end">
              <Vote
                lovelaceBalance={dRep.votingPower}
                label="Active Voting Power"
              />
            </div>
          )}
        </div>

        <div className="mt-4 flex flex-wrap items-center justify-between gap-2">
          <div className="flex items-center gap-3">
            {renderDelegationAndRegistrationButton()}
            <Button
              buttonSize="xsmall"
              variant="neutral"
              className={cn(isOwner && 'hidden sm:flex')}
              onClick={() => openModal('SHARE')}
            >
              Share
            </Button>
            {wallet.instance && isOwner && dRep && isDRepRegistered && (
              <Dropdown
                disabled={
                  !isEmpty(
                    pendingTransactions.pendingDRepRetireTransaction.txId
                  ) ||
                  !isEmpty(
                    pendingTransactions.pendingDRepRegistrationTransaction.txId
                  ) ||
                  isDelegationOrRetirementInProgress
                }
                contentWidth={196}
                anchor="bottom start"
                variant="neutral"
                isDropDownIconEnable={false}
                menuItems={getProfileMenuItem().filter((item) =>
                  React.isValidElement(item)
                )}
              />
            )}
          </div>
          <div className="flex items-center gap-2">
            {dRep && 'hasScript' in dRep && dRep.hasScript && <ScriptIcon />}
            {isOwner && dRep && dRep.status !== 'Retired' && (
              <Button
                disabled={isDelegationOrRetirementInProgress}
                onClick={() => router.push(`/${token.name}/edit`)}
                buttonSize="xsmall"
                variant="neutral"
              >
                <span className="!min-w-[63px]">Edit Profile</span>
                <EditIcon className="h-5 w-5" />
              </Button>
            )}

            {(fromDRepId || token) && (
              <CopyableField
                className={cn(isOwner && 'hidden sm:flex')}
                value={convertDRepToCIP129Format(dRepId)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
