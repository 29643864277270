import type { IMetadata } from '@src/models/dtos/metadata';
import { isEmpty } from 'lodash';
import CopyableField from '@src/components/atoms/copiableField';
import { isEmptyString } from '@src/utils/stringUtils';
import { getFieldValue } from '@src/utils/metadataUtils';
import ContentCard from '@src/components/cards/contentCard';
import EmptyContent from '@src/components/cards/emptyContentCard';
import { noContentDescription } from '@src/constants';
import React from 'react';
import type { IDetails } from '@src/models/types';

interface IDRepMetadata {
  metadata: IMetadata | null;
  isOwner: boolean;
}
export default function DRepMetadata({ metadata, isOwner }: IDRepMetadata) {
  const isMetadataEmpty = () => {
    if (
      !isEmptyString(getFieldValue(metadata?.body.motivations)) ||
      !isEmptyString(getFieldValue(metadata?.body.objectives)) ||
      !isEmptyString(getFieldValue(metadata?.body.qualifications))
    ) {
      return false;
    }

    return true;
  };

  const descriptions: IDetails[] = metadata
    ? [
        {
          label: 'Objectives',
          value: getFieldValue(metadata.body.objectives),
        },
        {
          label: 'Motivation',
          value: getFieldValue(metadata.body.motivations),
        },
        {
          label: 'Qualifications',
          value: getFieldValue(metadata.body.qualifications),
        },
      ]
    : [];

  if (isMetadataEmpty()) {
    return (
      <EmptyContent
        description={
          isOwner ? noContentDescription.owner.info : noContentDescription.user
        }
        metadata={isOwner ? metadata : null}
      />
    );
  }

  if (metadata && !isMetadataEmpty())
    return (
      <ContentCard className="relative flex w-full flex-col !overflow-hidden">
        <div className="flex flex-col gap-5">
          {descriptions.map(
            (description) =>
              !isEmpty(description.value) && (
                <div key={description.label} className="flex flex-col gap-2">
                  <p className="body18 font-semibold dark:text-white">
                    {description.label}
                  </p>
                  <p className="body14 whitespace-pre-line text-neutral-800 dark:text-gray-200">
                    {description.value
                      ?.toString()
                      .split('\\n')
                      .map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                </div>
              )
          )}

          {metadata.body.paymentAddress &&
            !isEmpty(getFieldValue(metadata.body.paymentAddress)) && (
              <div className="flex w-fit flex-col gap-2">
                <p className="body18 font-semibold dark:text-white">
                  Payment address
                </p>
                <CopyableField
                  fieldSize="small"
                  textClassName="sm:max-w-[297px] !max-w-[200px]"
                  value={getFieldValue(metadata.body.paymentAddress)}
                />
              </div>
            )}
        </div>
      </ContentCard>
    );

  return <></>;
}
