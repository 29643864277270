'use client';

import FourOFour from '@src/app/not-found';
import Button from '@src/components/atoms/buttons/button';
import StatesFooter from '@src/components/atoms/statesFooter';
import CustomizeToolTip from '@src/components/atoms/tooltip';
import BackArrow from '@src/components/icons/back';
import { useModal } from '@src/components/modal-views/context';
import EditProfileTabs from '@src/components/molecules/drep-profile/editProfileTabs';
import { emailRegex } from '@src/constants';
import { useFormHandlers } from '@src/lib/hooks/use-form-handler';
import type { IDRepFormField, IMetadata } from '@src/models/dtos/metadata';
import type { TokenType } from '@src/store/drep/types';
import type { RootState } from '@src/store/store';
import { findMetadataDifference } from '@src/utils/dRepUtils';
import { convertDRepMetadataToFormField } from '@src/utils/metadataUtils';
import { filterEmptyLinks } from '@src/utils/socialHelpers';
import { isEmpty } from 'lodash';
import { usePathname, useRouter } from 'next/navigation';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function EditProfileContainer({
  metadata,
  token,
  isEditDRep = true,
}: {
  metadata: IMetadata | null;
  isEditDRep?: boolean;
  token: TokenType | null;
}) {
  const router = useRouter();
  const { openModal } = useModal();
  const wallet = useSelector((state: RootState) => state.wallet);
  const pathName = usePathname();

  const initialMetadata = {
    givenName: '',
    image: undefined,
    motivations: '',
    objectives: '',
    paymentAddress: '',
    qualifications: '',
    references: [],
  };

  const [dRepRegistrationFormData, setDRepRegistrationFormData] =
    useState<IDRepFormField>(
      metadata ? convertDRepMetadataToFormField(metadata) : initialMetadata
    );

  const getChangesValue = () => {
    return findMetadataDifference(
      metadata ? convertDRepMetadataToFormField(metadata) : initialMetadata,
      {
        ...dRepRegistrationFormData,
        references: filterEmptyLinks(dRepRegistrationFormData.references),
      }
    );
  };
  const { identityLinksError, paymentAddressError, socialLinksError } =
    useFormHandlers(dRepRegistrationFormData, setDRepRegistrationFormData);

  const hasInvalidGivenName = isEmpty(dRepRegistrationFormData.givenName);

  const hasIdentityLinkErrors =
    !!identityLinksError &&
    identityLinksError.errors &&
    identityLinksError.errors.some((error) => !isEmpty(error.message));

  const hasPaymentAddressErrors = !isEmpty(paymentAddressError);

  const hasSocialLinkErrors =
    !!socialLinksError &&
    socialLinksError.errors &&
    socialLinksError.errors.some((error) => !isEmpty(error.message));

  const hasInvalidEmail =
    !!dRepRegistrationFormData.email &&
    !isEmpty(dRepRegistrationFormData.email) &&
    !emailRegex.test(dRepRegistrationFormData.email);

  const disableSaveChanges = () => {
    const hasNoChanges =
      getChangesValue() === null ||
      Object.keys(getChangesValue() || {}).length === 0;

    return (
      hasNoChanges ||
      hasInvalidGivenName ||
      hasIdentityLinkErrors ||
      hasPaymentAddressErrors ||
      hasSocialLinkErrors ||
      hasInvalidEmail
    );
  };

  const getToastMessage = () => {
    if (hasInvalidGivenName)
      return 'Please provide a valid given name. It cannot be null';
    if (hasInvalidEmail) return 'Please provide a valid email address';
    if (hasPaymentAddressErrors)
      return 'Please provide a valid payment address';
    if (hasSocialLinkErrors) return 'Please provide a valid social link';
    if (hasIdentityLinkErrors) return 'Please provide a valid identity link';

    return 'No changes to save';
  };

  const isWalletInvalid =
    !wallet.instance ||
    !wallet.walletInfo ||
    wallet.walletInfo?.drepIdBech32 !== token?.drepId;

  if (isWalletInvalid) {
    return <FourOFour />;
  }

  return (
    <div className="flex h-full min-h-calc-85 w-full flex-col items-center bg-white dark:bg-neutral-950">
      <div className="relative flex min-h-calc-142 w-full max-w-[1440px] flex-col gap-6 px-4 py-6 md:px-[72px] md:py-16 lg:px-[156px]">
        <div
          onClick={() => {
            const newPath = pathName.substring(0, pathName.lastIndexOf('/'));
            router.replace(newPath);
          }}
          className="flex cursor-pointer items-center gap-3"
        >
          <BackArrow className="text-dark-neutral-900 dark:text-white" />
          <span className="body16 font-bold dark:text-white">Back</span>
        </div>
        <div className="flex w-full items-center justify-between gap-10">
          <div className="flex flex-col gap-3">
            <p className="h36 font-bold dark:text-white">
              {!isEditDRep ? 'Create Profile' : 'Edit Profile'}
            </p>
            <p className="body14 font-medium dark:text-white xl:max-w-[600px]">
              Manage your account preferences, security settings, and payment
              settings. Add your social links and your overall account
              information.
            </p>
          </div>
          <Button
            data-tooltip-id="save changes"
            data-tooltip-place="top"
            disabled={disableSaveChanges()}
            className="min-w-[123px]"
            onClick={() => {
              openModal('REVIEW_YOUR_CHANGES', {
                dRepFormData: {
                  ...dRepRegistrationFormData,
                  references: filterEmptyLinks(
                    dRepRegistrationFormData.references
                  ),
                },
                changedValue: getChangesValue(),
                isEditDRep: isEditDRep,
              });
            }}
          >
            {disableSaveChanges() && (
              <CustomizeToolTip
                description={getToastMessage()}
                id="save changes"
              />
            )}
            Save Changes
          </Button>
        </div>
        <EditProfileTabs
          changesValue={getChangesValue()}
          dRepRegistrationFormData={dRepRegistrationFormData}
          setDRepRegistrationFormData={setDRepRegistrationFormData}
        />
      </div>
      <StatesFooter />
    </div>
  );
}
