import HoverCopyWrapper from '@src/components/atoms/HoverCopyWrapper';
import ContentCard from '@src/components/cards/contentCard';
import { formatAda } from '@src/utils/dRepUtils';
import type { IDRepRegistration } from '@src/store/drep/types';
import { seperateDateTime, timeAgo } from '@src/utils/date';
import RegistrationBadge from '@src/components/atoms/RegistrationBadge';
import { middleTruncate } from '@src/utils/stringUtils';
import AnchorLink from '@src/components/atoms/links/anchor-link';
import EmptyContent from '@src/components/cards/emptyContentCard';
import { noContentDescription } from '@src/constants';
import ExternalLink from '../icons/externalLink';
import { determineExternalExplorerLink } from '@src/utils/network';
import environments from '@src/configs/environments';
import { getNetworkNameFromURL } from '@src/utils/network';

interface DRepRegistrationsProps {
  registrations: IDRepRegistration[];
  isOwner: boolean;
}

export default function DRepRegistrations({
  registrations,
  isOwner,
}: DRepRegistrationsProps) {
  if (registrations.length === 0)
    return (
      <EmptyContent
        description={
          isOwner ? noContentDescription.owner.other : noContentDescription.user
        }
      />
    );
  return (
    <ContentCard>
      <table className="w-full rounded-lg border dark:border-dark-neutral-700">
        <thead>
          <tr>
            <th className="min-w-[100px] md:min-w-[160px]">Name</th>
            <th className="min-w-[86px]">Deposit</th>
            <th className="min-w-[111px]">Type</th>
            <th className="min-w-[240px]">URL</th>
            <th className="min-w-[144px]">Transaction</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {registrations.map((registration, index) => {
            const dateTime = seperateDateTime(registration.time.toString());
            return (
              <tr key={index}>
                <td>
                  <HoverCopyWrapper
                    className="label12 line-clamp-1 font-semibold dark:text-white"
                    value={registration.drepName}
                  >
                    {middleTruncate(registration.drepName || '-', 32)}
                  </HoverCopyWrapper>
                </td>
                <td className="label12 font-medium dark:text-white">
                  <span>
                    {`₳ ${formatAda(registration.deposit ? registration.deposit / 1000000 : 0)}`}
                  </span>
                </td>
                <td>
                  <RegistrationBadge amount={registration.deposit} />
                </td>
                <td>
                  <AnchorLink target="_blank" href={registration.url || '#'}>
                    <p className="label12 line-clamp-1 max-w-[216px] font-medium hover:underline dark:text-white">
                      {registration.url}
                    </p>
                  </AnchorLink>
                </td>
                <td className="flex flex-col items-start">
                  <span className="relative-time">
                    {timeAgo(registration.time.toString())}
                  </span>
                  <span className="transaction-date">
                    {dateTime.date} | {dateTime.time}
                  </span>
                </td>
                <td>
                  <AnchorLink
                    target="_blank"
                    href={`${determineExternalExplorerLink(
                      environments.CARDANO_NETWORK ||
                        (typeof window !== 'undefined'
                          ? getNetworkNameFromURL(window.location.href)
                          : 'MAINNET')
                    )}/transaction/${registration.txHash}?tab=dRepUpdates`}
                  >
                    <ExternalLink className="text-white" />
                  </AnchorLink>
                </td>
              </tr>
            );
          })}
        </tbody>
        {registrations.length === 0 && (
          <tbody>
            <tr>
              <td colSpan={5} className="text-center">
                No Registrations Found
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </ContentCard>
  );
}
