import InputField from '@src/components/atoms/textfield/inputField';
import type { IDRepFormField } from '@src/models/dtos/metadata';
import TabComponentLeftContent from '@src/components/molecules/edit-profile-tabs/tabComponentleftContent';
import ProfileImageSection from '@src/components/molecules/edit-profile-tabs/profile/profileImageSection';
import { useFormHandlers } from '@src/lib/hooks/use-form-handler';
import { isEmpty } from 'lodash';
import { emailRegex } from '@src/constants';
export default function ProfileTab({
  dRepRegistrationFormData,
  setDRepRegistrationFormData,
  handleNext,
}: {
  dRepRegistrationFormData: IDRepFormField;
  setDRepRegistrationFormData: (
    value: (prev: IDRepFormField) => IDRepFormField
  ) => void;
  handleNext: () => void;
}) {
  const { handleOnChange } = useFormHandlers(
    dRepRegistrationFormData,
    setDRepRegistrationFormData
  );
  const horizontalDivider = () => (
    <div className="h-[1px] w-full bg-layout-divider"></div>
  );

  const handleImageSelect = (image: File | string) => {
    setDRepRegistrationFormData((prevState: IDRepFormField) => ({
      ...prevState,
      image,
    }));
  };

  const handleErrorMessage = () => {
    if (isEmpty(dRepRegistrationFormData.email)) return '';
    return emailRegex.test(dRepRegistrationFormData.email || '')
      ? ''
      : 'Invalid email address';
  };

  return (
    <div className="flex flex-col gap-6">
      <ProfileImageSection
        selectedImage={dRepRegistrationFormData.image}
        onImageSelect={handleImageSelect}
      />
      {horizontalDivider()}
      <div className="flex w-full flex-col gap-5 md:flex-row md:gap-14">
        <TabComponentLeftContent
          handleNext={handleNext}
          title="Personal Information"
          description=" Update your profile picture, name, and other essential information
            to make your profile truly yours."
        />
        <div className="flex w-full flex-col gap-5">
          <InputField
            id="givenName"
            isRequired
            value={dRepRegistrationFormData.givenName}
            label="Given Name"
            onChange={handleOnChange}
            placeholder="eg. John Doe"
          />
          <InputField
            id="email"
            type="email"
            value={dRepRegistrationFormData.email}
            onChange={handleOnChange}
            label="Email Address"
            placeholder="eg. johndoe@email.com"
            helperText={handleErrorMessage()}
            variant={!isEmpty(handleErrorMessage()) ? 'error' : 'neutral'}
          />
          <InputField
            id="nationality"
            value={dRepRegistrationFormData.nationality}
            onChange={handleOnChange}
            label="Country"
            placeholder="eg. Uganda"
          />
        </div>
      </div>
    </div>
  );
}
