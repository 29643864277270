import { useState } from 'react';
import { getChangesValueStatus } from '@src/utils/dRepUtils';
import LucidAvatar from '@src/components/icons/lucidAvatar';
import UserPen from '@src/components/icons/userPen';
import BadgeDollarSign from '@src/components/icons/badgeDollarSign';
import ShareIcon from '@src/components/icons/share';
import IdCard from '@src/components/icons/idCard';
import type { ITabOption } from '@src/components/atoms/tabs';

export const useTabs = (changesValue: Record<string, any> | null) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const dRepTabs: ITabOption[] = [
    {
      value: 'Profile',
      icon: LucidAvatar,
      isActive: getChangesValueStatus('Profile', changesValue),
      tabClassName: 'min-w-[105px] rounded-l-lg',
    },
    {
      value: 'About yourself',
      icon: UserPen,
      isActive: getChangesValueStatus('About yourself', changesValue),
      tabClassName: 'min-w-[153px]',
    },
    {
      value: 'Payment Address',
      icon: BadgeDollarSign,
      isActive: getChangesValueStatus('Payment Address', changesValue),
      tabClassName: 'min-w-[173px]',
    },
    {
      value: 'Social Links',
      icon: ShareIcon,
      isActive: getChangesValueStatus('Social Links', changesValue),
      tabClassName: 'min-w-[134px]',
    },
    {
      value: 'Idenity',
      icon: IdCard,
      isActive: getChangesValueStatus('Idenity', changesValue),
      tabClassName: 'min-w-[104px] rounded-r-lg',
    },
  ];

  const handleNextStep = () => {
    if (activeIndex < dRepTabs.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  return { activeIndex, setActiveIndex, dRepTabs, handleNextStep };
};
