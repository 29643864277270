import React from 'react';

export default function UserPen(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.58333 12.5H5.83333C4.94928 12.5 4.10143 12.8512 3.47631 13.4763C2.85119 14.1014 2.5 14.9493 2.5 15.8333V17.5M17.815 13.8551C18.1469 13.5232 18.3334 13.0729 18.3334 12.6035C18.3334 12.134 18.1469 11.6838 17.815 11.3518C17.483 11.0198 17.0328 10.8333 16.5633 10.8333C16.0938 10.8333 15.6436 11.0198 15.3116 11.3518L11.97 14.6951C11.7719 14.8931 11.6268 15.1379 11.5483 15.4068L10.8508 17.7985C10.8299 17.8702 10.8286 17.9462 10.8472 18.0185C10.8657 18.0909 10.9034 18.1569 10.9562 18.2098C11.009 18.2626 11.075 18.3002 11.1474 18.3188C11.2198 18.3373 11.2958 18.336 11.3675 18.3151L13.7591 17.6176C14.028 17.5391 14.2728 17.3941 14.4708 17.196L17.815 13.8551ZM11.6667 5.83333C11.6667 7.67428 10.1743 9.16667 8.33333 9.16667C6.49238 9.16667 5 7.67428 5 5.83333C5 3.99238 6.49238 2.5 8.33333 2.5C10.1743 2.5 11.6667 3.99238 11.6667 5.83333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
