import EmptyContentIllustration from '@src/components/icons/emptyContentIllustration';
import Button from '@src/components/atoms/buttons/button';
import type { IMetadata } from '@src/models/dtos/metadata';
import { useParams, useRouter } from 'next/navigation';

interface EmptyContentProps {
  description?: string;
  metadata?: IMetadata | null;
}

export default function EmptyContent({
  description,
  metadata,
}: EmptyContentProps) {
  const router = useRouter();
  const params = useParams();
  return (
    <div className="flex h-[400px] flex-col items-center justify-center gap-6">
      <EmptyContentIllustration />
      <div className="flex flex-col gap-3 text-center">
        <p className="body18 font-semibold dark:text-white">
          No Content Available
        </p>
        <p className="body14 max-w-[278px] font-medium dark:text-gray-100">
          {description}
        </p>
      </div>
      {metadata != null && (
        <Button
          onClick={() => {
            if (params && 'dRepName' in params) {
              router.push(`/${params.dRepName}/edit`);
            }
          }}
          variant="neutral"
        >
          Add details
        </Button>
      )}
    </div>
  );
}
