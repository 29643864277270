import React from 'react';

export default function ExternalLink(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.83301 3.49967H3.49967C2.85534 3.49967 2.33301 4.02201 2.33301 4.66634V10.4997C2.33301 11.144 2.85534 11.6663 3.49967 11.6663H9.33301C9.97734 11.6663 10.4997 11.144 10.4997 10.4997V8.16634M8.16634 2.33301H11.6663M11.6663 2.33301V5.83301M11.6663 2.33301L5.83301 8.16634"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
