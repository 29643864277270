import TextField from '@src/components/atoms/textfield/textField';
import TabComponentLeftContent from '@src/components/molecules/edit-profile-tabs/tabComponentleftContent';
import type { IDRepFormField } from '@src/models/dtos/metadata';
import type { ChangeEvent } from 'react';

export default function AboutYourselfTab({
  dRepRegistrationFormData,
  onChange,
  handleNext,
}: {
  dRepRegistrationFormData: IDRepFormField | null;
  handleNext: () => void;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}) {
  return (
    <div className="flex flex-col gap-5 md:flex-row md:gap-14">
      <TabComponentLeftContent
        handleNext={handleNext}
        title="About Yourself"
        description="Share your story! Let others know who you are, what you’re passionate about, and what you’re here to accomplish."
      />
      <div className="flex w-full flex-col gap-5">
        <TextField
          id="objectives"
          onChange={onChange}
          value={dRepRegistrationFormData?.objectives}
          label="Objectives"
          placeholder="eg. I am a software engineer with a passion for building scalable web applications."
        />
        <TextField
          id="motivations"
          onChange={onChange}
          value={dRepRegistrationFormData?.motivations}
          label="Motivations"
          placeholder="eg. I am motivated by the desire to create innovative solutions to everyday problems."
        />
        <TextField
          id="qualifications"
          onChange={onChange}
          value={dRepRegistrationFormData?.qualifications}
          label="Qualifications"
          placeholder="eg. I have a degree in computer science and 5 years of experience in software development."
        />
      </div>
    </div>
  );
}
