export default function Web(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.54577 9.66667H4.16667C5.08714 9.66667 5.83333 10.4129 5.83333 11.3333V12.1667C5.83333 13.0871 6.57953 13.8333 7.5 13.8333C8.42047 13.8333 9.16667 14.5795 9.16667 15.5V17.9542M6.66667 3.7796V5.08333C6.66667 6.23393 7.59941 7.16667 8.75 7.16667H9.16667C10.0871 7.16667 10.8333 7.91286 10.8333 8.83333C10.8333 9.75381 11.5795 10.5 12.5 10.5C13.4205 10.5 14.1667 9.75381 14.1667 8.83333C14.1667 7.91286 14.9129 7.16667 15.8333 7.16667L16.7204 7.16667M12.5 17.5732V15.5C12.5 14.5795 13.2462 13.8333 14.1667 13.8333H16.7204M17.5 10.5C17.5 14.6421 14.1421 18 10 18C5.85786 18 2.5 14.6421 2.5 10.5C2.5 6.35786 5.85786 3 10 3C14.1421 3 17.5 6.35786 17.5 10.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
