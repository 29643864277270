import React from 'react';

export default function BadgeCheck(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4581_36052)">
        <path
          d="M6.75043 9.00024L8.25043 10.5002L11.2504 7.50024M2.88789 6.46532C2.77842 5.97221 2.79523 5.45945 2.93676 4.97457C3.07828 4.48969 3.33995 4.0484 3.69749 3.69161C4.05503 3.33482 4.49687 3.07408 4.98204 2.93356C5.46722 2.79305 5.98001 2.77732 6.47289 2.88782C6.74417 2.46354 7.11789 2.11439 7.5596 1.87253C8.00131 1.63068 8.4968 1.50391 9.00039 1.50391C9.50398 1.50391 9.99946 1.63068 10.4412 1.87253C10.8829 2.11439 11.2566 2.46354 11.5279 2.88782C12.0215 2.77683 12.5352 2.7925 13.0211 2.93335C13.5071 3.07421 13.9495 3.33567 14.3073 3.69343C14.665 4.05119 14.9265 4.49362 15.0674 4.97957C15.2082 5.46551 15.2239 5.97919 15.1129 6.47282C15.5372 6.7441 15.8863 7.11782 16.1282 7.55953C16.37 8.00124 16.4968 8.49673 16.4968 9.00032C16.4968 9.50391 16.37 9.99939 16.1282 10.4411C15.8863 10.8828 15.5372 11.2565 15.1129 11.5278C15.2234 12.0207 15.2077 12.5335 15.0671 13.0187C14.9266 13.5038 14.6659 13.9457 14.3091 14.3032C13.9523 14.6608 13.511 14.9224 13.0261 15.0639C12.5413 15.2055 12.0285 15.2223 11.5354 15.1128C11.2645 15.5387 10.8904 15.8894 10.448 16.1323C10.0055 16.3752 9.50891 16.5026 9.00414 16.5026C8.49937 16.5026 8.00276 16.3752 7.56029 16.1323C7.11783 15.8894 6.74382 15.5387 6.47289 15.1128C5.98001 15.2233 5.46722 15.2076 4.98204 15.0671C4.49687 14.9266 4.05503 14.6658 3.69749 14.309C3.33995 13.9522 3.07828 13.5109 2.93676 13.0261C2.79523 12.5412 2.77842 12.0284 2.88789 11.5353C2.46036 11.2647 2.1082 10.8904 1.86418 10.4472C1.62015 10.004 1.49219 9.50627 1.49219 9.00032C1.49219 8.49436 1.62015 7.99663 1.86418 7.55341C2.1082 7.11019 2.46036 6.73588 2.88789 6.46532Z"
          stroke="#11A862"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4581_36052">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
