import React from 'react';

export default function UserX(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 16.25C1.5 15.2061 1.77238 14.1802 2.29022 13.2737C2.80807 12.3673 3.55346 11.6116 4.45274 11.0814C5.35203 10.5512 6.37409 10.2648 7.41794 10.2506C8.46179 10.2363 9.4913 10.4946 10.4048 11M12.75 13.25L16.5 17M16.5 13.25L12.75 17M11.25 6.5C11.25 8.57107 9.57107 10.25 7.5 10.25C5.42893 10.25 3.75 8.57107 3.75 6.5C3.75 4.42893 5.42893 2.75 7.5 2.75C9.57107 2.75 11.25 4.42893 11.25 6.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
