import cn from 'classnames';
import Badge from '@src/components/atoms/badges/badge';
import { capitalize } from 'lodash';
export default function DelegationBadge({
  action,
}: {
  action: 'joined' | 'left';
}) {
  function getBadgeColor() {
    if (action === 'joined') {
      return 'success';
    } else {
      return 'error';
    }
  }
  return (
    <Badge
      className={cn('w-fit')}
      variant="light"
      color={getBadgeColor()}
      label={capitalize(action)}
    />
  );
}
